import React, {useEffect, useState } from 'react';
import axiosConfig from '../../utils/axios-config';
import cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Iframe from '../../helpers/iframe';
import './report-details.scss';

const ReportDetails = () => {

    const params = useParams();
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [report, setReport] = useState({});

    useEffect(() => {
        document.title = "Roxus | Report Details";
        let isMounted = true;
        if (currentUser && currentUser.id && isMounted) {
            fetchReport();
        }
        return () => { isMounted = false };
    }, []);

    const fetchReport = async () => {
        return await axiosConfig({
            method: 'get',
            url: `/api/settings/companyreports/${params.reportId}`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
            .then((response) => {
                let report = response.data.data;
                console.log('Report', report);
                setReport(report);
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    };



    return (
        <div className='reports'>
            <Iframe source={report?.reportUrl} />
        </div>
    );
};

export default withRouter(ReportDetails);
