import React, { useState, useEffect } from 'react';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Spin,
	Table,
	Tag,
	Row,
	Col,
	Input,
	Button,
	Popconfirm,
	Avatar,
	List,
	DatePicker,
	Select,
	message,
} from 'antd';
import { delay } from '../../helpers/helpers';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './main-logging-table.scss';
import { withRouter, useHistory, Link } from 'react-router-dom';

const MainLoggingTable = () => {
	let history = useHistory();
	const [messageApi, contextHolder] = message.useMessage();
	const [filterParams, setFilterParams] = useState({});
	const [customers, setCustomers] = useState([]);
	const [processDisable, setProcessDisable] = useState(true);
	const [processes, setProcesses] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchCriteria, setSearchCriteria] = useState({});
	const currentUser = useSelector((state) => state.auth.currentUser);

	useEffect(() => {
		let isMounted = true;
		if (currentUser && currentUser.id && isMounted) {
			// fetchTable();
			fetchCustomers();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		applySearchCriteria();
	}, [searchCriteria]);

	const applySearchCriteria = () => {
		let filterData = tableData;
		if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
			filterData = filterData.filter(
				(row) =>
					(row.customerName &&
						row.customerName
							.toLowerCase()
							.indexOf(searchCriteria.keyword.toLowerCase()) >
							-1) ||
					(row.projectName &&
						row.projectName
							.toLowerCase()
							.indexOf(searchCriteria.keyword.toLowerCase()) >
							-1) ||
					(row.processName &&
						row.processName
							.toLowerCase()
							.indexOf(searchCriteria.keyword.toLowerCase()) > -1)
			);
			setFilteredData(filterData);
		}
		setFilteredData(filterData);
	};

	const filterByKeyword = (e) => {
		delay(function () {
			setSearchCriteria({ ...searchCriteria, keyword: e.target.value });
		}, 500);
	};

	const fetchTable = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/roxuslogging/main-loggings`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				const data = response.data.map((res, index) => {
					index++;
					return {
						key: res.id,
						no: res.id,
						customerName: res.customerName,
						projectName: res.projectName,
						processName: res.processName,
						initiatedBy: res.initiatedBy,
						techStatus: res.techStatus,
						businessStatus: res.businessStatus,
						oSUserName: res.oSUserName,
						userName: res.userName,
						startComments: res.startComments,
						endComments: res.endComments,
						startTime: res.startTime,
						endTime: res.endTime,
					};
				});
				console.log('Table Data', data);
				setTableData(data);
				setFilteredData(data);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

	const fetchQueryTable = async (request) => {
		await axiosConfig({
			method: 'post',
			data: request,
			url: `/api/roxuslogging/query-main-loggings`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				const data = response.data.map((res, index) => {
					index++;
					return {
						key: res.id,
						no: res.id,
						customerName: res.customerName,
						projectName: res.projectName,
						processName: res.processName,
						initiatedBy: res.initiatedBy,
						techStatus: res.techStatus,
						businessStatus: res.businessStatus,
						oSUserName: res.oSUserName,
						userName: res.userName,
						startComments: res.startComments,
						endComments: res.endComments,
						startTime: res.startTime,
						endTime: res.endTime,
					};
				});
				console.log('Table Data', data);
				setTableData(data);
				setFilteredData(data);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

	const fetchCustomers = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/roxuslogging/logging-customers`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				let customerList = response.data;
				let newCustomers = customerList.map((c) => {
					return { value: c, label: c };
				});
				setCustomers(newCustomers);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

	const fetchProcessesByCustomer = async (request) => {
		await axiosConfig({
			method: 'post',
			url: `/api/roxuslogging/logging-processes`,
			data: request,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				let processList = response.data;
				let newProcesses = processList.map((p) => {
					return { value: p, label: p };
				});
				setProcesses(newProcesses);
				setProcessDisable(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

	const changePage = (page, logId) => {
		switch (page) {
			case 'main-logging':
				history.push(`/rpa/main-logging/${logId}`);
				break;
			default:
				break;
		}
	};

	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	const columns = [
		{
			title: 'Action',
			key: 'action',
			dataIndex: 'action',
			render: (text, record) => {
				return (
					<>
						<Button
							onClick={() =>
								changePage('main-logging', record.key)
							}
						>
							View Log
						</Button>
					</>
				);
			},
		},
		{
			title: 'Id',
			dataIndex: 'no',
			key: 'no',
		},
		{
			title: 'Customer Name',
			dataIndex: 'customerName',
			key: 'customerName',
		},
		{
			title: 'Project Name',
			dataIndex: 'projectName',
			key: 'projectName',
		},
		{
			title: 'Process Name',
			dataIndex: 'processName',
			key: 'processName',
		},
		{
			title: 'Initiated By',
			dataIndex: 'initiatedBy',
			key: 'initiatedBy',
		},
		{
			title: 'Tech Status',
			key: 'techStatus',
			dataIndex: 'techStatus',
		},
		{
			title: 'Business Status',
			key: 'businessStatus',
			dataIndex: 'businessStatus',
		},
		{
			title: 'OS Username',
			key: 'oSUserName',
			dataIndex: 'oSUserName',
		},
		{
			title: 'Username',
			key: 'userName',
			dataIndex: 'userName',
		},
		{
			title: 'Start Comments',
			key: 'startComments',
			dataIndex: 'startComments',
		},
		{
			title: 'End Comments',
			key: 'endComments',
			dataIndex: 'endComments',
		},
		{
			title: 'Start Time',
			key: 'startTime',
			dataIndex: 'startTime',
		},
		{
			title: 'End Time',
			key: 'endTime',
			dataIndex: 'endTime',
		},
	];

	const onCustomerChange = async (value) => {
		console.log(`selected ${value}`);
		setFilterParams({ ...filterParams, customerName: value });
		if (value) {
			let processRequest = { customerName: value };
			await fetchProcessesByCustomer(processRequest);
		} else {
			setProcessDisable(true);
		}
	};

	const onCustomerSearch = (value) => {
		console.log('search:', value);
	};

	const onProcessChange = (value) => {
		console.log(`selected ${value}`);
		setFilterParams({ ...filterParams, processName: value });
	};

	const onProcessSearch = (value) => {
		console.log('Process search:', value);
	};

	const onDateChange = (date, dateString) => {
		console.log(dateString);
		setFilterParams({ ...filterParams, date: dateString });
	};

	const filterProcesses = async () => {
		console.log('Filter Params', filterParams);
		let customerName = filterParams.customerName;
		let processName = filterParams.processName;
		let date = filterParams.date;

		if (!customerName) {
			error('Please select the Customer');
			return;
		} else if (!processName) {
			error('Please select the Process Name');
			return;
		} else if (!date) {
			error('Please select the Date');
			return;
		}

		await fetchQueryTable(filterParams);
	};

	const error = (errorContent) => {
		if (!errorContent) {
			errorContent = 'This is an error message';
		}
		messageApi.open({
			type: 'error',
			content: errorContent,
		});
	};

	// Filter `option.label` match the user type `input`
	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<>
			{contextHolder}
			<Spin spinning={loading}>
				<Row className='search-area' gutter={{ lg: 8 }}>
					<Col span={16}>
						<Select
							showSearch
							placeholder='Select Customer'
							optionFilterProp='children'
							onChange={onCustomerChange}
							onSearch={onCustomerSearch}
							filterOption={filterOption}
							options={customers}
							style={{ width: '250px' }}
						/>
						<Select
							showSearch
							disabled={processDisable}
							placeholder='Select Process'
							optionFilterProp='children'
							onChange={onProcessChange}
							onSearch={onProcessSearch}
							filterOption={filterOption}
							options={processes}
							style={{ width: '250px' }}
						/>
						<DatePicker
							placeholder='Select Date'
							onChange={onDateChange}
							style={{ width: '150px' }}
						/>
						<Button
							type='primary'
							onClick={filterProcesses}
							icon={<SearchOutlined />}
						>
							Search
						</Button>
					</Col>
					<Col span={8}>
						<Input
							id='search-box'
							onKeyUp={filterByKeyword}
							addonBefore={<SearchOutlined />}
							placeholder='Search'
						/>
					</Col>
				</Row>
				<Table columns={columns} dataSource={filteredData} />
			</Spin>
		</>
	);
};

export default MainLoggingTable;
