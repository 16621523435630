import React from 'react';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EditProfileTab from '../../components/edit-profile-tab/edit-profile-tab';
import SecurityTab from '../../components/security-tab/security-tab';
import ActivationTab from '../../components/activation-tab/activation-tab';
import './account.scss';
const { TabPane } = Tabs;

const Account = ({ history }) => {
    const currentUser = useSelector((state) => state.auth.currentUser);

    const getCurrentTab = () => {
        const url = window.location.href;
        let urls = url.split('#');
        switch (urls[1]) {
            case 'security':
                return 'security';
            case 'activation':
                return 'activation';
            default:
                return 'general';
        }
    };

    const changeTab = (key) => {
        history.push(`/account#${key}`);
    };

    return (
        <div className='account-page'>
            <div className='account-content'>
                <span className='account-text'>ACCOUNT</span>
            </div>
            <Tabs defaultActiveKey={getCurrentTab()} onChange={changeTab}>
                <TabPane tab='GENERAL' key='general'>
                    <EditProfileTab />
                </TabPane>
                <TabPane tab='SECURITY' key='security'>
                    <SecurityTab />
                </TabPane>
                {!currentUser?.isActivated && (
                    <TabPane tab='ACTIVATION' key='activation'>
                        <ActivationTab />
                    </TabPane>
                )}
            </Tabs>
        </div>
    );
};

export default withRouter(Account);
