import React, { useState, useEffect } from 'react';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import {
    Spin,
    Table,
    Tag,
    Row,
    Col,
    Input,
    Button,
    Popconfirm,
    Avatar,
    List,
} from 'antd';
import { delay } from '../../helpers/helpers';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './task-history-table.scss';
import { withRouter, useHistory, Link } from 'react-router-dom';

const TaskHistoryTable = () => {
    let history = useHistory();
    const [filteredData, setFilteredData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState({});
    const currentUser = useSelector((state) => state.auth.currentUser);

    useEffect(() => {
        let isMounted = true;
        if (currentUser && currentUser.id && isMounted) {
            // fetchTable();
            setFilteredData([
                {
                    "taskId": 0,
                    "status": "Cancelled",
                    "createdTime": "2021-07-03T01:57:59 -07:00",
                    "customerName": "Osborn Cannon",
                    "wizardCustomId": "Download XPM Report",
                    "priority": "Low"
                },
                {
                    "taskId": 1,
                    "status": "In Progress",
                    "createdTime": "2016-03-19T07:47:41 -07:00",
                    "customerName": "Rosemarie Calderon",
                    "wizardCustomId": "Integrate Airtable with Pipedrive",
                    "priority": "Medium"
                },
                {
                    "taskId": 2,
                    "status": "In Progress",
                    "createdTime": "2019-07-19T07:53:42 -07:00",
                    "customerName": "Walker Emerson",
                    "wizardCustomId": "Rain Gauge",
                    "priority": "Low"
                },
                {
                    "taskId": 3,
                    "status": "Cancelled",
                    "createdTime": "2024-02-10T12:56:30 -07:00",
                    "customerName": "Guadalupe Stein",
                    "wizardCustomId": "Integrate Airtable with Pipedrive",
                    "priority": "High"
                },
                {
                    "taskId": 4,
                    "status": "In Progress",
                    "createdTime": "2020-09-25T11:02:07 -07:00",
                    "customerName": "Glenn Mills",
                    "wizardCustomId": "Rain Gauge",
                    "priority": "Medium"
                },
                {
                    "taskId": 5,
                    "status": "Completed",
                    "createdTime": "2018-04-23T06:54:04 -07:00",
                    "customerName": "Lynette Lawrence",
                    "wizardCustomId": "Download XPM Report",
                    "priority": "Low"
                },
                {
                    "taskId": 6,
                    "status": "Completed",
                    "createdTime": "2017-06-28T12:11:42 -07:00",
                    "customerName": "Harmon Mathis",
                    "wizardCustomId": "Download XPM Report",
                    "priority": "Medium"
                }
            ]);
        }
        return () => {
            isMounted = false;
        };
    }, []);

    const changePage = (page, settingId) => {
        switch (page) {
            case 'create':
                history.push('/rpa/create-setting');
                break;
            case 'edit':
                history.push(`/rpa/edit-setting/${settingId}`);
                break;
            default:
                break;
        }
    };

    const columns = [
        {
            title: 'Task Id',
            dataIndex: 'taskId',
            key: 'taskId',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Created Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: 'Wizard Custom Id',
            key: 'wizardCustomId',
            dataIndex: 'wizardCustomId',
        },
        {
            title: 'Priority',
            key: 'priority',
            dataIndex: 'priority',
        },
    ];

    return (
        <Spin spinning={loading}>
            <Row className='search-area' gutter={{ lg: 8 }}>
                <Col span={8}>
                    <Button onClick={() => changePage('create')} type='primary'>
                        Trigger Task
                    </Button>
                </Col>
            </Row>
            <Table columns={columns} dataSource={filteredData} />
        </Spin>
    );
};

export default TaskHistoryTable;
