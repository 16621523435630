import React, { useEffect } from 'react';
import "./tag-list.scss";
import TagTable from '../../components/tag-table/tag-table';

const TagList = () => {
    useEffect(() => {
    document.title = 'Roxus | Tag List';
    }, [])

    return (
        <div className="tag-list-page">
            <div className="title">
                <span className="text">TAG LIST</span>
            </div>
            <TagTable />
        </div>
    )
}

export default TagList;
