import React, { useEffect } from 'react';
import './companies.scss';
import CompanyTable from '../../components/company-table/company-table';

const Companies = () => {
	useEffect(() => {
		document.title = 'Roxus | Company List';
	}, []);

	return (
		<div className='ocr-list-page'>
			<div className='ocr-title'>
				<span className='text'>COMPANY LIST</span>
			</div>
			<CompanyTable />
		</div>
	);
};

export default Companies;
