import React, { useEffect } from 'react';
import "./stream-list.scss";
import StreamTable from '../../components/stream-table/stream-table';

const StreamList = () => {
    useEffect(() => {
    document.title = 'Roxus | Assistant Streams';
    }, [])

    return (
        <div className="stream-list-page">
            <div className="title">
                <span className="text">ASSISTANT STREAM LIST</span>
            </div>
            <StreamTable />
        </div>
    )
}

export default StreamList;
