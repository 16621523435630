import React, { useEffect } from 'react';
import "./recording-list.scss";
import RecordingTable from '../../components/recording-table/recording-table';

const RecordingList = () => {
    useEffect(() => {
    document.title = 'Roxus | Event List';
    }, [])

    return (
        <div className="ocr-list-page">
            <div className="ocr-title">
                <span className="text">EVENT LIST</span>
            </div>
            <RecordingTable />
        </div>
    )   
}

export default RecordingList;
