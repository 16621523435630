import React, { useEffect } from 'react';
import './setting-list.scss';
import OtpTable from '../../components/otp-table/otp-table';

const RecordingList = () => {
	useEffect(() => {
		document.title = 'Roxus | Setting List';
	}, []);

	return (
		<div className='ocr-list-page'>
			<div className='ocr-title'>
				<span className='text'>GET OTP</span>
			</div>
			<OtpTable />
		</div>
	);
};

export default RecordingList;
