import React, { useState, useEffect } from 'react';
import axiosConfig from '../../utils/axios-config';
import cookies from 'js-cookie';
import {
    Row,
    Col,
    Card,
    Form,
    Select,
    Input,
    Divider,
    Avatar,
    Upload,
    Spin,
    Tag,
    message,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import GraButton from '../button/gra-button';
import './edit-profile-tab.scss';
import { UserOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { changeUserProfileImage, updateCurrentUser } from '../../redux/users';

const { Option } = Select;

const EditProfileTab = () => {
    const [changeImageLoading, setChangeImageLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [form] = Form.useForm();

    const auth = useSelector((state) => state.auth);
    const { currentUser } = auth;

    const dispatch = useDispatch();

    useEffect(() => {
        form.setFieldsValue({
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            gender: currentUser?.gender,
            phoneNumber: currentUser?.phoneNumber,
            jobTitle: currentUser?.jobTitle,
            company: currentUser?.company,
            mailingStreet: currentUser?.mailingStreet,
            mailingCity: currentUser?.mailingCity,
            mailingState: currentUser?.mailingState,
            mailingCountry: currentUser?.mailingCountry,
            mailingZip: currentUser?.mailingZip,
            email: currentUser?.email,
        });
    }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
    };

    const fullName =
        currentUser?.firstName && currentUser?.lastName
            ? currentUser?.firstName + ' ' + currentUser?.lastName
            : '';

    const avatar = () => {
        return currentUser && currentUser.profileImage ? (
            <Avatar size={100} src={currentUser.profileImage} />
        ) : (
            <Avatar size={100} icon={<UserOutlined />} />
        );
    };

    const onFinish = async (values) => {
        console.log('Received values of form: ', values);

        try {
            setUpdateLoading(true);
            const response = await axiosConfig({
                url: `/api/nanonets/users/${currentUser.id}`,
                data: values,
                method: 'put',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            console.log(`response`, response);
            dispatch(updateCurrentUser(response.data));
            message.success('User updated successfully!');
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setUpdateLoading(false);
        }
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const beforeUpload = (file) => {
        if (!file.type.includes('image/')) {
            message.error(`${file.name} is not an image file`);
        } else {
            handleUpload(file);
        }
        return false;
    };

    const handleUpload = async (file) => {
        const formData = new FormData();
        const customerName = 'nanonets';
        const userId = currentUser.id;

        try {
            setChangeImageLoading(true);
            formData.append('file', file);
            const data = { formData, customerName, userId };

            await dispatch(changeUserProfileImage(data)).unwrap();
        } catch (error) {
            if (error.response) {
                message.error(error.response.data);
            } else {
                message.error(error.message);
            }
        } finally {
            setChangeImageLoading(false);
        }
    };

    return (
        <div className='edit-profile-tab'>
            <Row>
                <Col span={6} className='change-avatar'>
                    <Card>
                        <Spin spinning={changeImageLoading}>
                            <div>{avatar()}</div>
                            <div className='full-name'>
                                <span>{fullName}</span>
                            </div>
                            <div className='job-title'>
                                <span>{currentUser?.jobTitle}</span>
                            </div>
                            <div className='mt-sm'>
                                <Tag
                                    color={
                                        currentUser?.isActivated
                                            ? 'success'
                                            : 'error'
                                    }
                                >
                                    {currentUser?.isActivated
                                        ? 'ACTIVATED'
                                        : 'NOT ACTIVATED'}
                                </Tag>
                            </div>
                            <div className='upload-button'>
                                <ImgCrop rotate>
                                    <Upload
                                        maxCount={1}
                                        beforeUpload={beforeUpload}
                                        accept='image/*'
                                        onPreview={onPreview}
                                    >
                                        <GraButton className='button-gra-inv'>
                                            Change Image
                                        </GraButton>
                                    </Upload>
                                </ImgCrop>
                            </div>
                        </Spin>
                    </Card>
                </Col>
                <Col span={17} offset={1}>
                    <Card title='Profile'>
                        <Spin spinning={updateLoading}>
                            <Form
                                {...formItemLayout}
                                form={form}
                                name='register'
                                onFinish={onFinish}
                                scrollToFirstError
                            >
                                <Row>
                                    <Col span={11}>
                                        <Form.Item
                                            colon={false}
                                            name='firstName'
                                            label='First Name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input your First Name!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='lastName'
                                            label='Last Name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input your Last Name!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='email'
                                            label='Email'
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='gender'
                                            label='Gender'
                                        >
                                            <Select placeholder='Select Your Gender'>
                                                <Option value={1}>Male</Option>
                                                <Option value={2}>
                                                    Female
                                                </Option>
                                                <Option value={0}>Other</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='phoneNumber'
                                            label='Phone Number'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input your phone number!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='jobTitle'
                                            label='Job Title'
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='company'
                                            label='Company'
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col offset={1} span={10}>
                                        <Form.Item
                                            colon={false}
                                            name='mailingStreet'
                                            label='Mailing Street'
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='mailingCity'
                                            label='Mailing City'
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='mailingState'
                                            label='Mailing State'
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='mailingCountry'
                                            label='Mailing Country'
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='mailingZip'
                                            label='Postal Code'
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Divider />
                                    <Col span={24} className='submit-button'>
                                        <Form.Item>
                                            <GraButton
                                                className='button-gra-inv'
                                                type='submit'
                                            >
                                                Update Profile
                                            </GraButton>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default EditProfileTab;
