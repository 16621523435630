import React, { useEffect } from 'react';
import {
	Button,
	Form,
	Input,
	Select,
	notification,
} from 'antd';
import { Countries }  from '../../constants/country';
import { withRouter, useHistory, Link } from 'react-router-dom';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import './create-company.scss';

const CreateCompany = () => {
	let history = useHistory();
	const tailLayout = {
		wrapperCol: {
			offset: 4,
			span: 12,
		},
	};

	const [form] = Form.useForm();
	const delay = (ms) => new Promise((res) => setTimeout(res, ms));
	const openNotificationWithIcon = (type, errorDetail) => {
		switch (type) {
			case 'success':
				notification[type]({
					message: 'Create Company SUCCESSFULLY',
				});

				break;
			case 'error':
				notification[type]({
					message: 'Create Company FAILED',
					description: errorDetail,
				});
				break;
			default:
				break;
		}
	};

	const onFinish = async (values) => {
		console.log(JSON.stringify(values));

		await axiosConfig({
			method: 'post',
			data: values,
			url: `/api/settings/companies`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then(async (response) => {
				console.log(response);
				openNotificationWithIcon('success');
				await delay(3000);
				history.push('/settings/companies');
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
				openNotificationWithIcon('error', error.response.data);
			});
	};

	const onReset = () => {
		form.resetFields();
	};

	const changePage = (page) => {
		switch (page) {
			case 'companies':
				history.push('/settings/companies');
				break;
			default:
				break;
		}
	};

	const onCountryChange = (value) => {
		console.log(`selected ${value}`);
	};

	const onCountrySearch = (value) => {
		console.log('search:', value);
	};

	useEffect(() => {
		document.title = 'Roxus | Create Company';
	}, []);

	return (
		<>
			<div className='ocr-list-page'>
				<div className='ocr-title'>
					<span className='text'>CREATE COMPANY</span>
				</div>
			</div>
			<Form
				labelCol={{
					span: 4,
				}}
				wrapperCol={{
					span: 16,
				}}
				form={form}
				name='dynamic_form_complex'
				onFinish={onFinish}
				autoComplete='off'
				initialValues={{
					items: [{}],
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<>
						<Form.Item
							label='Company Name'
							name='Name'
							rules={[
								{
									required: true,
									message: 'Company Name is required',
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item label='Company Number' name='CompanyNumber'>
							<Input />
						</Form.Item>
                        <Form.Item label='Website' name='Website'>
							<Input />
						</Form.Item>
						<Form.Item label='Number of Employees' name='NumberOfEmployees'>
							<Input />
						</Form.Item>
						<Form.Item label='Field of Expertise' name='FieldOfExpertise'>
							<Input />
						</Form.Item>
						<Form.Item label='Country' name='FieldOfExpertise'>
							<Select
								showSearch
								placeholder="Select a country"
								optionFilterProp="children"
								onChange={onCountryChange}
								onSearch={onCountrySearch}
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								options={Countries.map(c => {
									return {"label": c.name, "value": c.name}
								})}
							/>
						</Form.Item>
					</>
				</div>
				<Form.Item {...tailLayout}>
					<Button type='primary' htmlType='submit'>
						Save
					</Button>
					<Button htmlType='button' onClick={onReset}>
						Clear
					</Button>
					<Button
						htmlType='button'
						onClick={() => changePage('companies')}
					>
						Don't Save
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default CreateCompany;
