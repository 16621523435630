import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import cookies from 'js-cookie';
import axiosConfig from '../utils/axios-config';

export const refreshAccessToken = createAsyncThunk(
    'users/refresh',
    async (data) => {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/nanonets/refreshtoken',
            data,
        });
        return response.data;
    }
);

export const changeUserProfileImage = createAsyncThunk(
    'users/changeProfileImage',
    async (data) => {
        const { formData, customerName, userId } = data;
        const response = await axiosConfig({
            url: `/api/blob/${customerName}/users/${userId}/uploadprofilepicture`,
            data: formData,
            method: 'post',
            headers: { Authorization: `Bearer ${cookies.get('access_token')}` },
        });
        return response.data;
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        currentUser: {},
        status: 'idle',
        lastFetch: null,
    },
    reducers: {
        setCurrentUser(user, action) {
            const response = action.payload;
            user.currentUser = response.data;
            cookies.set('access_token', response.data.accessToken);
            cookies.set('refresh_token', response.data.refreshToken);
        },

        updateCurrentUser(user, action) {
            user.currentUser = action.payload;
        },

        updateUserActivation(user, action) {
            user.currentUser.isActivated = true;
        },
    },
    extraReducers(builder) {
        builder.addCase(refreshAccessToken.pending, (user, action) => {
            user.status = 'loading';
        });
        builder.addCase(refreshAccessToken.fulfilled, (user, action) => {
            user.status = 'success';
            const response = action.payload;
            user.currentUser = response.data;
            cookies.set('access_token', response.data.accessToken);
            cookies.set('refresh_token', response.data.refreshToken);
        });
        builder.addCase(refreshAccessToken.rejected, (user, action) => {
            user.status = 'failed';
        });
        builder.addCase(changeUserProfileImage.fulfilled, (user, action) => {
            user.currentUser.profileImage = action.payload.profileImage;
        });
    },
});

export const {
    userRequested,
    userWithTokenReceived,
    userUpdated,
    userRequestFailed,
    userUpdateFailed,
    setCurrentUser,
    updateCurrentUser,
    updateUserActivation,
} = authSlice.actions;
export default authSlice.reducer;
