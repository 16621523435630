import React from 'react';
import { Layout } from 'antd';
import SideBar from '../../components/sidebar/sidebar';
import HeaderComp from '../../components/header/header';
import './main.scss';

const Main = ({selectedKey, openKey, children}) => {
    return (
        <Layout>
            <HeaderComp />
            <Layout className="roxus-sidebar">
                <SideBar selectedKey={selectedKey} openKey={openKey} />
                <div className="main-content">
                    {children}
                </div>
            </Layout>
        </Layout>
    )
}

export default Main;
