import React from 'react';
import "./file-list.scss";
import OcrTable from '../../components/ocr-table/ocr-table';

const OcrFileList = () => {
    return (
        <div className="ocr-list-page">
            <div className="ocr-title">
                <span className="text">FILE LIST</span>
            </div>
            <OcrTable />
        </div>
    )
}

export default OcrFileList;
