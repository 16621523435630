import React, { useEffect } from 'react';
import './main-logging.scss';
import MainLoggingTable from '../../components/logging-table/main-logging-table';

const MainLoggingList = () => {
	useEffect(() => {
		document.title = 'Roxus | Main Logging List';
	}, []);

	return (
		<div className='ocr-list-page'>
			<div className='ocr-title'>
				<span className='text'>MAIN LOGGINGS</span>
			</div>
			<MainLoggingTable />
		</div>
	);
};

export default MainLoggingList;
