import React, { useState, useEffect } from 'react';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import axiosConfig from '../../utils/axios-config';
import cookies from 'js-cookie';
import { Countries }  from '../../constants/country';
import {
    Row,
    Col,
    Card,
    Form,
    Spin,
	Table,
	Input,
	Button,
	Popconfirm,
	Modal,
    Select,
    Divider,
    message
} from 'antd';
import { delay } from '../../helpers/helpers';
import './models-tab.scss';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;

const ModelsTab = () => {
    const params = useParams();
    const [assignedModels, setAssignedModels] = useState([]);
    const [allModels, setAllModels] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(true);
    const [createLoading, setCreateLoading] = useState(false);
	const [searchCriteria, setSearchCriteria] = useState({});
	const currentUser = useSelector((state) => state.auth.currentUser);

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const [createForm] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
    };

    const showCreateModal = () => {
        setCreateModalOpen(true);
    };
    const handleCreateOk = () => {
        createForm.submit();
    };
    const handleCreateCancel = () => {
        setCreateModalOpen(false);
    };

    useEffect(() => {
        document.title = 'Roxus | Manage Models';
		fetchTable();
        fetchModels();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const assignModel = async (values) => {
        console.log('Received values of form: ', values);

        let requestBody = null;
        if (values && values.models && values.models.length > 0)
        {
            let modelIds = values.models.toString();
            requestBody = {};
            requestBody.modelIds = modelIds;
        }

        if (!requestBody)
        {
            message.error("");
            return;
        }

        try {
            setCreateLoading(true);
            const response = await axiosConfig({
                url: `/api/settings/companies/${params.companyId}/assign-models`,
                data: requestBody,
                method: 'post',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            console.log(`response`, response);
            message.success('Assign Models SUCCESSFULLY!');
            await fetchTable();
            setCreateModalOpen(false);
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setCreateLoading(false);
        }
    };

    const fetchTable = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/settings/companies/${params.companyId}/models`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				const data = response.data.data.map((res, index) => {
					index++;
					return {
						key: res.modelId,
						no: res.modelId,
						modelName: res.modelName,
                        modelDescription: res.modelDescription,
						createdTime: res.createdDate,
						modifiedTime: res.modifiedDate,
					};
				});
                const models = response.data.data.map((res, index) => {
					index++;
					return res.modelId;
				});
                setAssignedModels(models);

                let formData = {};
                formData.models = models;
                console.log('Form Data', formData);

                createForm.setFieldsValue(formData);
				console.log('Table Data', data);

				setTableData(data);
				setFilteredData(data);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

    const fetchModels = async () => {
        await axiosConfig({
			method: 'get',
			url: `/api/nanonets/models`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				const data = response.data.data.map((res, index) => {
					index++;
					return {
						value: res.modelId,
                        label: res.modelName,
					};
				});
				console.log('All Models', data);
				setAllModels(data);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
    }
    const applySearchCriteria = () => {
		let filterData = tableData;
		if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
			filterData = filterData.filter(
				(row) =>
					row.Name
						.toLowerCase()
						.indexOf(searchCriteria.keyword.toLowerCase()) > -1
			);
			setFilteredData(filterData);
		}
		setFilteredData(filterData);
	};

	const filterByKeyword = (e) => {
		delay(function () {
			setSearchCriteria({ ...searchCriteria, keyword: e.target.value });
		}, 500);
	};

    const columns = [
		{
			title: 'Model Name',
			dataIndex: 'modelName',
			key: 'modelName',
		},
        {
            title: 'Description',
            dataIndex: 'modelDescription',
            key: 'modelDescription'
        },
		{
			title: 'Created Time',
			key: 'createdTime',
			dataIndex: 'createdTime',
            render: (text) => {
				return (
				  text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
				);
			  },
		},
		{
			title: 'Modified Time',
			key: 'modifiedTime',
			dataIndex: 'modifiedTime',
            render: (text) => {
				return (
				  text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
				);
			  },
		},
	];

    return (
        <>
            <Spin spinning={loading}>
                <Row className='search-area' gutter={{ lg: 8 }}>
                    <Col span={8}>
                        <Button type='primary' onClick={showCreateModal}>
                            Assign Models
                        </Button>
                    </Col>
                    <Col span={8} offset={8}>
                        <Input
                            id='search-box'
                            onKeyUp={filterByKeyword}
                            addonBefore={<SearchOutlined />}
                            placeholder='Search'
                        />
                    </Col>
                </Row>
                <Table columns={columns} dataSource={filteredData} />
            </Spin>
            <Form
                {...formItemLayout}
                form={createForm}
                name='assignModel'
                onFinish={assignModel}
                scrollToFirstError
                loading={createLoading}
            >
                <Modal title="Assign Models" visible={createModalOpen} onOk={handleCreateOk} onCancel={handleCreateCancel} okText="Assign Models">
                    <Row>
                        <Col span={24}>
                            <Spin spinning={createLoading}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            colon={false}
                                            name='models'
                                            label='Models'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select Models',
                                                },
                                            ]}
                                        >
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Please select Models"
                                                options={allModels}
                                            />

                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Spin>
                        </Col>
                    </Row>
                </Modal>
            </Form>
            
        </>
    );
};

export default ModelsTab;
