import React, { useState, useEffect } from 'react';
import { Select, Form, Input, Button, message } from 'antd';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import cookies from 'js-cookie';
import './upload-recording.scss';

const UploadRecording = () => {
	const [form] = Form.useForm();
	const [uploading, setUploading] = useState(false);
	const currentUser = useSelector((state) => state.auth.currentUser);

	useEffect(() => {
		document.title = 'Roxus | Upload Recording';
	});

	const onFinish = async (values) => {
		console.log(values);
		const formData = new FormData();
		formData.append('recordUrl', values.bloburl);
		formData.append('locale', values.locale);
		try {
			setUploading(true);
			let response = await axiosConfig({
				method: 'post',
				url: `/api/cognitive/transcriptions/${currentUser.id}/speechtotext`,
				data: formData,
				headers: {
					Authorization: `Bearer ${cookies.get('access_token')}`,
				},
			});
			console.log(response);
			message.success(response.data.message);
		} catch (error) {
			console.log(error);
			message.error(error.response.data.message);
		} finally {
			setUploading(false);
		}
	};

	return (
		<div className='upload-recording-page'>
			<div className='upload-title'>
				<span className='text'>UPLOAD RECORDING</span>
			</div>
			<Form form={form} onFinish={onFinish}>
				<div>
					<Form.Item
						name='bloburl'
						label='Blob URL'
						rules={[
							{ required: true, message: 'Blob URL is required' },
						]}
						style={{ width: 800 }}
					>
						<Input />
					</Form.Item>
				</div>
				<div>
					<Form.Item
						name='locale'
						label='Locale'
						rules={[
							{ required: true, message: 'Locale is required' },
						]}
						style={{ width: 800 }}
					>
						<Select
							showSearch
							placeholder='Select locale'
							value='en-AU'
							style={{ width: 200 }}
							filterOption={(input, option) =>
								(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={[
								{
									label: 'Afrikaans (South Africa)',
									value: 'af-ZA',
								},
								{
									label: 'Amharic (Ethiopia)',
									value: 'am-ET',
								},
								{
									label: 'Arabic (United Arab Emirates)',
									value: 'ar-AE',
								},
								{
									label: 'Arabic (Bahrain)',
									value: 'ar-BH',
								},
								{
									label: 'Arabic (Algeria)',
									value: 'ar-DZ',
								},
								{
									label: 'Arabic (Egypt)',
									value: 'ar-EG',
								},
								{
									label: 'Arabic (Israel)',
									value: 'ar-IL',
								},
								{
									label: 'Arabic (Iraq)',
									value: 'ar-IQ',
								},
								{
									label: 'Arabic (Jordan)',
									value: 'ar-JO',
								},
								{
									label: 'Arabic (Kuwait)',
									value: 'ar-KW',
								},
								{
									label: 'Arabic (Lebanon)',
									value: 'ar-LB',
								},
								{
									label: 'Arabic (Libya)',
									value: 'ar-LY',
								},
								{
									label: 'Arabic (Morocco)',
									value: 'ar-MA',
								},
								{
									label: 'Arabic (Oman)',
									value: 'ar-OM',
								},
								{
									label: 'Arabic (Palestinian Territories)',
									value: 'ar-PS',
								},
								{ label: 'Arabic (Qatar)', value: 'ar-QA' },
								{
									label: 'Arabic (Saudi Arabia)',
									value: 'ar-SA',
								},
								{
									label: 'Arabic (Syria)',
									value: 'ar-SY',
								},
								{
									label: 'Arabic (Tunisia)',
									value: 'ar-TN',
								},
								{
									label: 'Arabic (Yemen)',
									value: 'ar-YE',
								},
								{
									label: 'Azerbaijani (Latin, Azerbaijan)',
									value: 'az-AZ',
								},
								{
									label: 'Bulgarian (Bulgaria)',
									value: 'bg-BG',
								},
								{
									label: 'Bengali (India)',
									value: 'bn-IN',
								},
								{
									label: 'Bosnian (Bosnia and Herzegovina)',
									value: 'bs-BA',
								},
								{
									label: 'Catalan (Spain)',
									value: 'ca-ES',
								},
								{
									label: 'Czech (Czechia)',
									value: 'cs-CZ',
								},
								{
									label: 'Welsh (United Kingdom)',
									value: 'cy-GB',
								},
								{
									label: 'Danish (Denmark)',
									value: 'da-DK',
								},
								{ label: 'German (Austria)', value: 'de-AT' },
								{
									label: 'German (Switzerland)',
									value: 'de-CH',
								},
								{
									label: 'German (Germany)',
									value: 'de-DE',
								},
								{
									label: 'Greek (Greece)',
									value: 'el-GR',
								},
								{
									label: 'English (Australia)',
									value: 'en-AU',
								},
								{
									label: 'English (Canada)',
									value: 'en-GB',
								},
								{
									label: 'English (Ghana)',
									value: 'en-GH',
								},
								{
									label: 'English (Hong Kong SAR)',
									value: 'en-HK',
								},
								{
									label: 'English (Ireland)',
									value: 'en-IE',
								},
								{
									label: 'English (India)',
									value: 'en-IN',
								},
								{
									label: 'English (Kenya)',
									value: 'en-KE',
								},
								{
									label: 'English (Nigeria)',
									value: 'en-NG',
								},
								{
									label: 'English (New Zealand)',
									value: 'en-NZ',
								},
								{
									label: 'English (Philippines)',
									value: 'en-PH',
								},
								{
									label: 'English (Singapore)',
									value: 'en-SG',
								},
								{
									label: 'English (Tanzania)',
									value: 'en-TZ',
								},
								{
									label: 'English (United States)',
									value: 'en-US',
								},
								{
									label: 'English (South Africa)',
									value: 'en-ZA',
								},
								{
									label: 'Spanish (Argentina)',
									value: 'es-AR',
								},
								{
									label: 'Spanish (Bolivia)',
									value: 'es-BO',
								},
								{
									label: 'Spanish (Chile)',
									value: 'es-CL',
								},
								{
									label: 'Spanish (Colombia)',
									value: 'es-CO',
								},
								{
									label: 'Spanish (Costa Rica)',
									value: 'es-CR',
								},
								{
									label: 'Spanish (Cuba)',
									value: 'es-CU',
								},
								{
									label: 'Spanish (Dominican Republic)',
									value: 'es-DO',
								},
								{
									label: 'Spanish (Ecuador)',
									value: 'es-EC',
								},
								{
									label: 'Spanish (Spain)',
									value: 'es-ES',
								},
								{
									label: 'Spanish (Equatorial Guinea)',
									value: 'es-GQ',
								},
								{
									label: 'Spanish (Guatemala)',
									value: 'es-GT',
								},
								{
									label: 'Spanish (Honduras)',
									value: 'es-HN',
								},
								{
									label: 'Spanish (Mexico)',
									value: 'es-MX',
								},
								{
									label: 'Spanish (Nicaragua)',
									value: 'es-NI',
								},
								{
									label: 'Spanish (Panama)',
									value: 'es-PA',
								},
								{
									label: 'Spanish (Peru)',
									value: 'es-PE',
								},
								{
									label: 'Spanish (Puerto Rico)',
									value: 'es-PR',
								},
								{
									label: 'Spanish (Paraguay)',
									value: 'es-PY',
								},
								{
									label: 'Spanish (El Salvador)',
									value: 'es-SV',
								},
								{
									label: 'Spanish (United States)',
									value: 'es-US',
								},
								{
									label: 'Spanish (Uruguay)',
									value: 'es-UY',
								},
								{
									label: 'Spanish (Venezuela)',
									value: 'es-VE',
								},
								{
									label: 'Estonian (Estonia)',
									value: 'et-EE',
								},
								{
									label: 'Basque',
									value: 'eu-ES',
								},
								{
									label: 'Persian (Iran)',
									value: 'fa-IR',
								},
								{
									label: 'Finnish (Finland)',
									value: 'fi-FI',
								},
								{
									label: 'Filipino (Philippines)',
									value: 'fil-PH',
								},
								{
									label: 'French (Belgium)',
									value: 'fr-BE',
								},
								{
									label: 'French (Canada)',
									value: 'fr-CA',
								},
								{
									label: 'French (Switzerland)',
									value: 'fr-CH',
								},
								{
									label: 'French (France)',
									value: 'fr-FR',
								},
								{
									label: 'Irish (Ireland)',
									value: 'ga-IE',
								},
								{
									label: 'Galician',
									value: 'gl-ES',
								},
								{
									label: 'Gujarati (India)',
									value: 'gu-IN',
								},
								{
									label: 'Hebrew (Israel)',
									value: 'he-IL',
								},
								{
									label: 'Hindi (India)',
									value: 'hi-IN',
								},
								{
									label: 'Croatian (Croatia)',
									value: 'hr-HR',
								},
								{
									label: 'Hungarian (Hungary)',
									value: 'hu-HU',
								},
								{
									label: 'Armenian (Armenia)',
									value: 'hy-AM',
								},
								{
									label: 'Indonesian (Indonesia)',
									value: 'id-ID',
								},
								{
									label: 'Icelandic (Iceland)',
									value: 'is-IS',
								},
								{
									label: 'Italian (Switzerland)',
									value: 'it-CH',
								},
								{
									label: 'Italian (Italy)',
									value: 'it-IT',
								},
								{
									label: 'Japanese (Japan)',
									value: 'ja-JP',
								},
								{
									label: 'Javanese (Latin, Indonesia)',
									value: 'jv-ID',
								},
								{
									label: 'Georgian (Georgia)',
									value: 'ka-GE',
								},
								{
									label: 'Kazakh (Kazakhstan)',
									value: 'kk-KZ',
								},
								{
									label: 'Khmer (Cambodia)',
									value: 'km-KH',
								},
								{
									label: 'Kannada (India)',
									value: 'kn-IN',
								},
								{
									label: 'Korean (Korea)',
									value: 'ko-KR',
								},
								{
									label: 'Lao (Laos)',
									value: 'lo-LA',
								},
								{
									label: 'Lithuanian (Lithuania)',
									value: 'lt-LT',
								},
								{
									label: 'Latvian (Latvia)',
									value: 'lv-LV',
								},
								{
									label: 'Macedonian (North Macedonia)',
									value: 'mk-MK',
								},
								{
									label: 'Malayalam (India)',
									value: 'ml-IN',
								},
								{
									label: 'Mongolian (Mongolia)',
									value: 'mn-MN',
								},
								{
									label: 'Marathi (India)',
									value: 'mr-IN',
								},
								{
									label: 'Malay (Malaysia)',
									value: 'ms-MY',
								},
								{
									label: 'Maltese (Malta)',
									value: 'mt-MT',
								},
								{
									label: 'Burmese (Myanmar)',
									value: 'my-MM',
								},
								{
									label: 'Norwegian Bokmål (Norway)',
									value: 'nb-NO',
								},
								{
									label: 'Nepali (Nepal)',
									value: 'ne-NP',
								},
								{
									label: 'Dutch (Belgium)',
									value: 'nl-BE',
								},
								{
									label: 'Dutch (Netherlands)',
									value: 'nl-NL',
								},
								{
									label: 'Polish (Poland)',
									value: 'pl-PL',
								},
								{
									label: 'Pashto (Afghanistan)',
									value: 'ps-AF',
								},
								{
									label: 'Portuguese (Brazil)',
									value: 'pt-BR',
								},
								{
									label: 'Portuguese (Portugal)',
									value: 'pt-PT',
								},
								{
									label: 'Romanian (Romania)',
									value: 'ro-RO',
								},
								{
									label: 'Russian (Russia)',
									value: 'ru-RU',
								},
								{
									label: 'Sinhala (Sri Lanka)',
									value: 'si-LK',
								},
								{
									label: 'Slovak (Slovakia)',
									value: 'sk-SK',
								},
								{
									label: 'Slovenian (Slovenia)',
									value: 'sl-SI',
								},
								{
									label: 'Somali (Somalia)',
									value: 'so-SO',
								},
								{
									label: 'Albanian (Albania)',
									value: 'sq-AL',
								},
								{
									label: 'Serbian (Cyrillic, Serbia)',
									value: 'sr-RS',
								},
								{
									label: 'Swedish (Sweden)',
									value: 'sv-SE',
								},
								{
									label: 'Swahili (Kenya)',
									value: 'sw-KE',
								},
								{
									label: 'Swahili (Tanzania)',
									value: 'sw-TZ',
								},
								{
									label: 'Tamil (India)',
									value: 'ta-IN',
								},
								{
									label: 'Telugu (India)',
									value: 'te-IN',
								},
								{
									label: 'Thai (Thailand)',
									value: 'th-TH',
								},
								{
									label: 'Turkish (Turkey)',
									value: 'uk-UA',
								},
								{
									label: 'Uzbek (Latin, Uzbekistan)',
									value: 'uz-UZ',
								},
								{
									label: 'Vietnamese (Vietnam)',
									value: 'vi-VN',
								},
								{
									label: 'Chinese (Wu, Simplified)',
									value: 'wuu-CN',
								},
								{
									label: 'Chinese (Cantonese, Simplified)',
									value: 'yue-CN',
								},
								{
									label: 'Chinese (Mandarin, Simplified)',
									value: 'zh-CN',
								},
								{
									label: 'Chinese (Jilu Mandarin, Simplified)',
									value: 'zh-CN-shandong',
								},
								{
									label: 'Chinese (Southwestern Mandarin, Simplified)',
									value: 'zh-CN-sichuan',
								},
								{
									label: 'Chinese (Cantonese, Traditional))',
									value: 'zh-HK',
								},
								{
									label: 'Chinese (Taiwanese Mandarin, Traditional)',
									value: 'zh-TW',
								},
								{
									label: 'Zulu (South Africa)',
									value: 'zu-ZA',
								},
							]}
						/>
					</Form.Item>
				</div>
				<div>
					<Button
						loading={uploading}
						type='primary'
						htmlType='submit'
						style={{ marginTop: 16 }}
					>
						{uploading ? 'Uploading' : 'Start Upload'}
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default UploadRecording;
