import React from 'react';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import './assets/styles/global.scss';
import LoginPage from './pages/login/login';
import Main from './pages/main/main';
import Dashboard from './pages/dashboard/dashboard';
import Account from './pages/account/account';
import OcrFileList from './pages/ocr/file-list';
import OcrUploadFile from './pages/ocr/upload-file';
import UploadRecording from './pages/recording/upload-recording';
import RecordingFileList from './pages/recording/recording-list';
import RecordingDetails from './pages/recording-details/recording-details';
import StreamList from './pages/stream/stream-list';
import StreamDetails from './pages/stream-details/stream-details';
import TagList from './pages/tag/tag-list';
import PrivateRoute from './pages/private-route/private-route';
import ActivatePage from './pages/activation/activate-page';
import SettingList from './pages/rpa/setting-list';
import CreateSetting from './pages/rpa/create-setting';
import EditSetting from './pages/rpa/edit-setting';
import MainLogging from './pages/main-logging/main-logging';
import MainLoggingDetails from './pages/main-logging/main-logging-details';
import Pipeline from './pages/process-discovery/pipeline';
import Discovery from './pages/process-discovery/discovery';
import Companies from './pages/settings/companies';
import CreateCompany from './pages/settings/create-company';
import CompanyDetails from './pages/settings/company-details';
import { checkAuthenticate } from './services/user-services';
import SetUpPassword from './pages/activation/set-up-password';
import { useSelector } from 'react-redux';
import Page404 from './pages/page404/page-404';
import GetOTP from './pages/rpa/get-otp';
import TaskHistory from './pages/rpa/task-history';
import Reports from './pages/reports/reports';
import ReportDetails from './pages/reports/report-details';

function App() {

	let authenticated = checkAuthenticate();

	console.log("Authenticated", authenticated);

	return (
		<Switch>
			<Route
				exact
				path='/login'
				render={() => {
					if (!authenticated) {

						return <LoginPage />;
					}
					else {
						return <Redirect to="/" />;
					}
				}}
			/>
			<PrivateRoute
				exact
				path='/'
				render={() => (
					<Main selectedKey={['1']}>
						<Dashboard />
					</Main>
				)}
			/>
			<Route
				exact
				path='/register/activate/:activationCode'
				render={() => <ActivatePage />}
			/>
			<Route
				exact
				path='/setup-password/:activationCode'
				render={() => <SetUpPassword />}
			/>

			<PrivateRoute
				exact
				path='/account'
				render={() => (
					<Main selectedKey={['1']}>
						<Account />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/ocr/file-list'
				render={() => (
					<Main selectedKey={['3']} openKey={['documents']}>
						<OcrFileList />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/ocr/upload'
				render={() => (
					<Main selectedKey={['4']} openKey={['documents']}>
						<OcrUploadFile />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/events/:recordingId'
				render={() => (
					<Main selectedKey={['event-list']} openKey={['agents']}>
						<RecordingDetails />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/events'
				render={() => (
					<Main selectedKey={['event-list']} openKey={['agents']}>
						<RecordingFileList />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/streams'
				render={() => (
					<Main
						selectedKey={['assistant-stream']}
						openKey={['agents']}
					>
						<StreamList />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/streams/:streamId'
				render={() => (
					<Main
						selectedKey={['assistant-stream']}
						openKey={['agents']}
					>
						<StreamDetails />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/upload-recording'
				render={() => (
					<Main
						selectedKey={['upload-recording']}
						openKey={['agents']}
					>
						<UploadRecording />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/tags'
				render={() => (
					<Main selectedKey={['tag-list']} openKey={['agents']}>
						<TagList />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/rpa/process-config'
				render={() => (
					<Main selectedKey={['process-config']} openKey={['rpa', 'configurations']}>
						<SettingList />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/rpa/create-setting'
				render={() => (
					<Main selectedKey={['setting-list']} openKey={['rpa']}>
						<CreateSetting />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/rpa/edit-setting/:settingId'
				render={() => (
					<Main selectedKey={['setting-list']} openKey={['rpa']}>
						<EditSetting />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/rpa/main-logging'
				render={() => (
					<Main selectedKey={['main-logging']} openKey={['rpa']}>
						<MainLogging />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/rpa/main-logging/:loggingId'
				render={() => (
					<Main selectedKey={['main-logging']} openKey={['rpa']}>
						<MainLoggingDetails />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/rpa/task-history'
				render={() => (
					<Main selectedKey={['get-otp']} openKey={['rpa', 'configurations']}>
						<TaskHistory />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/rpa/get-otp'
				render={() => (
					<Main selectedKey={['get-otp']} openKey={['rpa', 'configurations']}>
						<GetOTP />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/pd/pipeline'
				render={() => (
					<Main selectedKey={['pipeline']} openKey={['pd']}>
						<Pipeline />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/pd/discovery'
				render={() => (
					<Main selectedKey={['discovery']} openKey={['pd']}>
						<Discovery />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/settings/companies'
				render={() => (
					<Main selectedKey={['companies']} openKey={['settings']}>
						<Companies />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/settings/create-company'
				render={() => (
					<Main selectedKey={['companies']} openKey={['settings']}>
						<CreateCompany />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/settings/companies/:companyId'
				render={() => (
					<Main selectedKey={['companies']} openKey={['settings']}>
						<CompanyDetails />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/reports'
				render={() => (
					<Main selectedKey={['reports']}>
						<Reports />
					</Main>
				)}
			/>
			<PrivateRoute
				exact
				path='/companyreports/:reportId'
				render={() => (
					<Main selectedKey={['1']}>
						<ReportDetails />
					</Main>
				)}
			/>
			
		</Switch>
	);
}

export default withRouter(App);
