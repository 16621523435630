import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Tag, Row, Col, Input } from 'antd';
import { delay } from '../../helpers/helpers';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './recording-table.scss';
import { Link } from 'react-router-dom';

const RecordingTable = () => {
	const [filteredData, setFilteredData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchCriteria, setSearchCriteria] = useState({});
	const currentUser = useSelector((state) => state.auth.currentUser);

	useEffect(() => {
		let isMounted = true;
		if (currentUser && currentUser.id && isMounted) {
			fetchTable();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		applySearchCriteria();
	}, [searchCriteria]);

	const applySearchCriteria = () => {
		let filterData = tableData;
		if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
			filterData = filterData.filter(
				(row) =>
					row.recordingName
						.toLowerCase()
						.indexOf(searchCriteria.keyword.toLowerCase()) > -1
			);
			setFilteredData(filterData);
		}
		setFilteredData(filterData);
	};

	const filterByKeyword = (e) => {
		delay(function () {
			setSearchCriteria({ ...searchCriteria, keyword: e.target.value });
		}, 500);
	};

	const fetchTable = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/cognitive/recordings`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				const data = response.data.data.map((res, index) => {
					index++;
					return {
						key: res.id,
						no: res.id,
						recordingName: res.recordingName,
						agentName: res.agentName,
						companyName: res.companyName,
						processName: res.processName,
						roxusBlobUrl: res.roxusBlobUrl,
						status: res.status,
						created: res.created,
						createdBy: res.createdBy,
					};
				});
				console.log('Table Data', data);
				setTableData(data);
				setFilteredData(data);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

	const columns = [
		{
			title: 'Action',
			key: 'action',
			dataIndex: 'action',
			render: (text, record) => {
				if (record.status == 1) {
					return (
						<Link
							to={'events/' + record.key}
							target='_blank'
							rel='noopener noreferrer'
						>
							<FileSearchOutlined /> View Details
						</Link>
					);
				} else {
					return '';
				}
			},
		},
		{
			title: 'Recording Name',
			dataIndex: 'recordingName',
			key: 'recordingName',
		},
		{
			title: 'Company Name',
			dataIndex: 'companyName',
			key: 'companyName',
		},
		{
			title: 'Matching Process',
			dataIndex: 'processName',
			key: 'processName',
			render: (text) => {
				return <Link>{text}</Link>;
			},
		},
		{
			title: 'Recording File',
			dataIndex: 'roxusBlobUrl',
			key: 'roxusBlobUrl',
			render: (text, record) => {
				return (
					<ReactAudioPlayer
						src={record.roxusBlobUrl}
						controls
						id={`recording-${record.key}`}
					/>
				);
			},
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: (status) => {
				let color = '';
				let text = '';
				switch (status) {
					case 0:
						color = 'blue';
						text = 'Running';
						break;
					case 1:
						color = 'green';
						text = 'Succeeded';
						break;
					default:
						break;
				}
				return (
					<Tag color={color} key={status}>
						{text}
					</Tag>
				);
			},
		},
		{
			title: 'Created Time',
			key: 'created',
			dataIndex: 'created',
		},
	];

	return (
		<Spin spinning={loading}>
			<Row className='search-area' gutter={{ lg: 8 }}>
				<Col span={8} offset={16}>
					<Input
						id='search-box'
						onKeyUp={filterByKeyword}
						addonBefore={<SearchOutlined />}
						placeholder='Search (Recording Name)'
					/>
				</Col>
			</Row>
			<Table columns={columns} dataSource={filteredData} />
		</Spin>
	);
};

export default RecordingTable;
