import React, {useEffect, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import axiosConfig from '../../utils/axios-config';
import cookies from 'js-cookie';
import RecordingDetailsTable from '../../components/recording-details/recording-details-table';
import OccasionTable from '../../components/occasion-table/occasion-table';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Row, Col, Button, Dropdown, Space, message, Tag, Tabs } from 'antd';
import { DashOutlined } from '@ant-design/icons';
import "./recording-details.scss";
const { TabPane } = Tabs;

const RecordingDetails = () => {
    const params = useParams();
    const [expectedTags, setExpectedTags] = useState();
    const [actualTags, setActualTags] = useState();
    const [recording, setRecording] = useState();
    const currentUser = useSelector((state) => state.auth.currentUser);

    useEffect(() => {
        document.title = "Roxus | Event Details";
        let isMounted = true;
        if (currentUser && currentUser.id && isMounted) {
            fetchRecording()
        }
        return () => { isMounted = false };
      }, []);

    useEffect(() => {
        let isMounted = true;
        if (recording?.processId && isMounted) {
            fetchTagsByProcess();
        }
        return () => { isMounted = false };
    }, [recording?.processId]);

    const fetchRecording = async() => {
        return await axiosConfig({
            method: 'get',
            url: `/api/cognitive/recordings/${params.recordingId}`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
        .then((response) => {
            let recording = response.data.data;
            console.log('Recording', recording);
            if(recording.actualTags) {
                let actualArray = recording.actualTags.split('|');
                setActualTags(actualArray);
            }
            setRecording(recording);
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    };

    

    const fetchTagsByProcess = async() => {
        return await axiosConfig({
            method: 'get',
            url: `/api/cognitive/tags-by-process/${recording?.processId}`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
        .then((response) => {
            let expectedTags = response.data.data;
            console.log('Expected Tags', expectedTags);
            setExpectedTags(expectedTags);
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    };
    
    const handleMenuClick = async(e) => {
        await markAgent(e.key);
    };

    const markAgent = async(agentChannel) => {
        if (agentChannel == null || agentChannel == undefined) {
            agentChannel = 'swap';
        }
        await axiosConfig({
          method: 'put',
          url: `/api/cognitive/recordings/${recording.id}/mark-agent/${agentChannel}`,
          headers: {
            Authorization: `Bearer ${cookies.get('access_token')}`,
          },
        })
        .then((response) => {
            console.log(response);
            message.success(response.data.message);
            fetchRecording();
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            message.error(error.response.data.message);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
      }

      const matchToProcess = async() => {
        await axiosConfig({
          method: 'put',
          url: `/api/cognitive/recordings/${recording.id}/match-to-process/1`,
          headers: {
            Authorization: `Bearer ${cookies.get('access_token')}`,
          },
        })
        .then((response) => {
            console.log(response);
            message.success(response.data.message);
            fetchRecording();
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            message.error(error.response.data.message);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
      }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key={0}>Channel 0</Menu.Item>
            <Menu.Item key={1}>Channel 1</Menu.Item>
        </Menu>
      );

    const changePlayTime = (playTime) => {
        if (typeof playTime == 'number') {
            var audio = document.getElementById('recording');
            audio.currentTime = playTime;
            audio.play();
        }
    }

    return (
        <div className='recording-details-page'>
            <Row justify='space-between'>
                <Col className='title'><span className='text'>EVENT DETAILS</span></Col>
                <Col className='button-assign'>
                    {recording && !recording.processId &&
                        <Button onClick={matchToProcess}>
                        Match To Process
                        </Button>
                    }
                    
                    {recording?.agentChannel == null &&
                    (<Dropdown overlay={menu}>
                        <Button>
                            <Space>
                            Mark Agent as
                            <DashOutlined />
                            </Space>
                        </Button>
                    </Dropdown>)}
                    {recording?.agentChannel != null && 
                    (
                        <Button onClick={markAgent}>
                            Swap Agent Channel
                        </Button>
                    )}
                </Col>
            </Row>
            <Row className='details'>
                <span className='label'>Recording Name:&nbsp;</span><span>{recording?.recordingName}</span>&nbsp;&nbsp;&nbsp;<span className='pipe'>|</span>&nbsp;&nbsp;&nbsp;
                <span className='label'>Company Name:&nbsp;</span><span>{recording?.companyName}</span>&nbsp;&nbsp;&nbsp;<span className='pipe'>|</span>&nbsp;&nbsp;&nbsp;
                <span className='label'>Expected Tags:&nbsp;</span><span>
                    {expectedTags && expectedTags.length > 0 && expectedTags.map(tag => {
                        return <Tag color={tag.color}>{tag.tagName}</Tag>
                })}</span>&nbsp;&nbsp;&nbsp;<span className='pipe'>|</span>&nbsp;&nbsp;&nbsp;
                <span className='label'>Actual Tags:&nbsp;</span>
                <span>
                    {actualTags && actualTags.length > 0 && actualTags.map(tag => {
                        let tagSplit = tag.split(';');
                        let tagName = tagSplit[0];
                        let tagColor = tagSplit[1];
                        return <Tag color={tagColor}>{tagName}</Tag>
                })}</span>&nbsp;&nbsp;&nbsp;<span className='pipe'>|</span>&nbsp;&nbsp;&nbsp;
                <span className='label'>Created Time:&nbsp;</span><span>{recording?.created}</span>
            </Row>
            <Row className='audio-player'>
                <ReactAudioPlayer
                    src={`${recording?.roxusBlobUrl}`}
                    controls
                    id={`recording`}
                />
            </Row>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Transcriptions" key="1">
                    <RecordingDetailsTable agentChannel={recording?.agentChannel} recordingId={params.recordingId} changePlayTime={changePlayTime} />
                </TabPane>
                {
                    recording && recording.streamId && (
                    <TabPane tab="Assistant Occasions" key="2">
                        <OccasionTable streamId={recording?.streamId} />
                    </TabPane>)
                }    
            </Tabs>
        </div>
    )
}
export default RecordingDetails;
