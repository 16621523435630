import React, { useEffect } from 'react';
import "./pipeline.scss";

const Pipeline = () => {
    useEffect(() => {
    document.title = 'Roxus | Pipeline';
    }, [])

    return (
        <div className="ocr-list-page">
            <div className="ocr-title">
                <span className="text">PIPELINE</span>
            </div>
        </div>
    )   
}

export default Pipeline;
