// First we need to import axios.js
import axios from 'axios';

// Next we make an 'instance' of it
const instance = axios.create({
    // .. where we make our configurations
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_API_URL : process.env.REACT_APP_DEV_API_URL
});

// Also add/ configure interceptors && all the other cool stuff
export default instance;