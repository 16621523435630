import React from 'react';

const Iframe = ({ source }) => {

    if (!source) {
        return <div>Loading...</div>;
    }

    const src = source;
    return (
        <div style={{display: 'flex', width:'100%', height: '100%', flexDirection: 'column'}} class="row-container">
            <iframe style={{border: 'none', flexGrow: 1, margin: 0, padding: 0, height: '80vh'}} frameBorder={0} src={src}></iframe>
        </div>
    );
};

export default Iframe;