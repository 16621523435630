import React, { useEffect } from 'react';
import './task-history.scss';
import SettingTable from '../../components/setting-table/setting-table';

const RecordingList = () => {
	useEffect(() => {
		document.title = 'Roxus | Setting List';
	}, []);

	return (
		<div className='ocr-list-page'>
			<div className='ocr-title'>
				<span className='text'>TASK HISTORY</span>
			</div>
			<SettingTable />
		</div>
	);
};

export default RecordingList;
