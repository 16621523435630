import React from 'react';
import { Row, Col, Modal } from 'antd';
import HappyGirl from '../../assets/images/activate/happy-girl.png';
import './activate-modal.scss';
import GraButton from '../button/gra-button';

const ActivateModal = (props) => {
    const { email } = props;

    return (
        <Modal className='activate-modal' {...props}>
            <Row justify='center'>
                <Col>
                    <span className='activate-title'>ACTIVATE ACCOUNT</span>
                </Col>
            </Row>
            <Row className='mt-md' justify='center'>
                <Col>
                    <span>An activation email was send to</span>
                </Col>
            </Row>
            <Row justify='center'>
                <Col>
                    <span className='activate-email'>{email}</span>
                </Col>
            </Row>
            <Row className='mt-sm' justify='center'>
                <Col>
                    <div>
                        Please login and enter the <b>6-digit code</b>
                    </div>
                    <div>to activate your account.</div>
                </Col>
            </Row>
            <Row className='mt-sm' justify='center'>
                <Col>
                    <img src={HappyGirl} alt='' className='activate-image' />
                </Col>
            </Row>
            <Row className='mt-sm' justify='center'>
                <Col>
                    <span>Got no email?</span>
                </Col>
            </Row>
            <Row className='mt-sm' justify='center'>
                <Col>
                    <GraButton className='button-gra-inv'>
                        <span>Resend Email</span>
                    </GraButton>
                </Col>
            </Row>
        </Modal>
    );
};

export default ActivateModal;
