import React from "react";
import "./gra-button.scss";

const GraButton = (props) => {
  const { children, type } = props;
  return (
    <button
      {...props}
      type={type}
    >
      {children}
    </button>
  );
};

export default GraButton;
