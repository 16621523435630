import React, { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Tag, Row, Col, Input } from 'antd';
import { delay } from '../../helpers/helpers';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './tag-table.scss';
import { Link } from 'react-router-dom';

const TagTable = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState({});
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    let isMounted = true;
    if (currentUser && currentUser.id && isMounted) {
      fetchTable();
    } 
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    applySearchCriteria();
  }, [searchCriteria]);

  const applySearchCriteria = () => {
    let filterData = tableData;
    if (searchCriteria.keyword) {
      filterData = filterData.filter(row => 
        row.tagName?.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1 
        || row.processName?.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1
        || row.keyword?.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1
        || row.keyPhrase?.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1
        || row.description?.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1
        || row.detailedDescription?.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1);
      setFilteredData(filterData);
    }
    setFilteredData(filterData);
  }

  const filterByKeyword = (e) => {
    delay(function(){
      setSearchCriteria({...searchCriteria, keyword: e.target.value});
    }, 500);
  }

  const fetchTable = async() => {
    await axiosConfig({
      method: 'get',
      url: `/api/cognitive/tags`,
      headers: {
        Authorization: `Bearer ${cookies.get('access_token')}`,
      },
    }).then((response) => {
        console.log(response);
        const data = response.data.data.map((res, index) => {
          index++;
          return {
            key: res.id,
            no: res.id,
            tagName: res.tagName,
            color: res.color,
            description: res.description,
            detailedDescription: res.detailedDescription,
            keyword: res.keyword,
            keyPhrase: res.keyPhrase,
            processName: res.processName,
            created: res.created,
            createdBy: res.createdBy
          };
        });
        console.log('Table Data', data);
        setTableData(data);
        setFilteredData(data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        setLoading(false);
        console.log(error.config);
      });
  };

  const columns = [
    {
      title: 'Tag Name',
      dataIndex: 'tagName',
      key: 'tagName',
      render: (text, record) => {
          return (<Tag color={record.color}>{text}</Tag>);
      }
    },
    {
        title: 'Process Name',
        dataIndex: 'processName',
        key: 'processName',
        render: (text) => {
            return (<Link>{text}</Link>);
        }
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Detailed Description',
        dataIndex: 'detailedDescription',
        key: 'detailedDescription',
    },
    {
        title: 'Keyword',
        dataIndex: 'keyword',
        key: 'keyword',
        render: (text) => {
            let keywords = text.split('|');
            return keywords.map((keyword, index) => {
                keyword = keyword.trim();
                if (index == keywords.length - 1) {
                    return (<Tag>{keyword}</Tag>);
                }
                else {
                    return (<><Tag>{keyword}</Tag><br></br></>);
                }
            })
        }
    },
    {
        title: 'Key Phrase',
        dataIndex: 'keyPhrase',
        key: 'keyPhrase',
        render: (text) => {
            return (<i>{text}</i>)
        }
    },
    {
      title: 'Created Time',
      key: 'created',
      dataIndex: 'created',
    },
  ];

  return (
    <Spin spinning={loading}>
      <Row className='search-area' gutter={{lg: 8}}>
        <Col span={12} offset={12}>
          <Input id='search-box' onKeyUp={filterByKeyword} addonBefore={<SearchOutlined />} placeholder='Search (Tag Name, Process Name, Description, Detailed Description, Keyword, Key Phrase)'/>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
      />
    </Spin>
  );
};

export default TagTable;
