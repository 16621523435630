import React, { useEffect } from 'react';
import "./discovery.scss";

const Discovery = () => {
    useEffect(() => {
    document.title = 'Roxus | Discovery';
    }, [])

    return (
        <div className="ocr-list-page">
            <div className="ocr-title">
                <span className="text">DISCOVERY</span>
            </div>
        </div>
    )
}

export default Discovery;
