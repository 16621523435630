import React, { useEffect } from 'react';
import './main-logging-details.scss';
import LoggingDetailsTable from '../../components/logging-table/logging-details-table';

const RecordingList = () => {
	useEffect(() => {
		document.title = 'Roxus | Main Logging Details';
	}, []);

	return (
		<div className='ocr-list-page'>
			<div className='ocr-title'>
				<span className='text'>MAIN LOGGING DETAILS</span>
			</div>
			<LoggingDetailsTable />
		</div>
	);
};

export default RecordingList;
