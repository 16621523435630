import React, { useEffect } from 'react';
import './setting-list.scss';
import TaskHistoryTable from '../../components/task-history-table/task-history-table';

const RecordingList = () => {
	useEffect(() => {
		document.title = 'Roxus | Setting List';
	}, []);

	return (
		<div className='ocr-list-page'>
			<div className='ocr-title'>
				<span className='text'>Task History</span>
			</div>
			<TaskHistoryTable />
		</div>
	);
};

export default RecordingList;
