import React from 'react';
import {Row, Col} from 'antd';
import LoginBackground from '../../components/login/background';
import LoginForm from '../../components/login/signin-form';
import './login.scss';

const LoginPage = () => {
    return (
        <Row className="login-page">
            <Col span={14} className="left-part">
                <LoginBackground/>
            </Col>
            <Col span={10} className="right-part">
                <LoginForm/>
            </Col>
        </Row>
    )
}

export default LoginPage

