import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Layout, Row, Col, Form, Input, Spin, Space } from 'antd';
import HeaderComp from '../../components/header/header';
import HandShake from '../../assets/images/activate/handshake.png';
import SadFace from '../../assets/images/activate/sad-face.png';
import Activate from '../../assets/images/activate/activate.png';
import GraButton from '../../components/button/gra-button';
import axiosConfig from '../../utils/axios-config';
import './activate-page.scss';

const ActivatePage = ({ history }) => {
    const { activationCode } = useParams();

    const onFinish = (values) => {
        console.log(values);
    };

    const activateContent = (
        <Row justify='center' align='middle' className='content'>
            <Col>
                <img src={Activate} width={200} alt='' />{' '}
            </Col>
            <Col offset={1}>
                <div>
                    <span className='activate-status'>
                        Activate Your Account
                    </span>
                </div>
                <div className='mt-md'>
                    <span>
                        Please enter your email in this form below and click{' '}
                        <b>ACTIVATE</b>,
                        <br /> so we can send you email to activate your
                        account!
                        <br /> Or if you have already activated your account,
                        please <b>Go to Login Page</b>.
                    </span>
                </div>
            </Col>
        </Row>
    );

    const [pageContent, setPageContent] = useState(activateContent);
    console.log(activationCode);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (activationCode) {
            verifyCode();
        }
    }, []);

    const verifyCode = () => {
        setLoading(true);
        axiosConfig({
            method: 'post',
            url: `/api/nanonets/register/activate/${activationCode}`,
        })
            .then((response) => {
                setPageContent(successContent);
                setLoading(false);
            })
            .catch((error) => {
                setPageContent(failContent);
                setLoading(false);
            });
    };

    const successContent = (
        <Row justify='center' align='middle' className='content'>
            <Col>
                <img src={HandShake} width={200} alt='' />{' '}
            </Col>
            <Col offset={1}>
                <div>
                    <span className='activate-status'>
                        Yay! Your account is already active
                    </span>
                </div>
                <div className='mt-md'>
                    <span>
                        Now you can start using Roxus OCR by going to the Login
                        Page and enter your credentials.
                        <br />
                        We hope you have a great time here.
                    </span>
                </div>
                <div className='mt-md'>
                    <GraButton
                        onClick={() => {
                            history.push('/login');
                        }}
                        className='button-gra-inv'
                    >
                        Log in
                    </GraButton>
                </div>
            </Col>
        </Row>
    );

    const failContent = (
        <Row justify='center' align='middle' className='content'>
            <Col>
                <img src={SadFace} width={200} alt='' />{' '}
            </Col>
            <Col offset={1}>
                <div>
                    <span className='activate-status'>
                        Oops! The activation code
                        <br /> is invalid or expired
                    </span>
                </div>
                <div className='mt-md'>
                    <span>
                        Please enter your email in this form below, so we can
                        send you the activation email again!
                    </span>
                </div>
                <Form
                    name='email-form'
                    className='login-form mt-md'
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name='email'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input size='large' placeholder='Email' />
                    </Form.Item>
                </Form>
                <div className='mt-md'>
                    <GraButton className='button-gra-inv' type='submit'>
                        Activate
                    </GraButton>
                </div>
            </Col>
        </Row>
    );

    return (
        <Layout>
            <HeaderComp />
            <Spin spinning={loading}>
                <Layout className='activate-page'>{pageContent}</Layout>
            </Spin>
        </Layout>
    );
};

export default withRouter(ActivatePage);
