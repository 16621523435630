import cookies from 'js-cookie';

export const checkAuthenticate = () => {
    const accessToken = cookies.get('access_token');
    const refreshToken = cookies.get('refresh_token');

    if (accessToken && refreshToken) {
        return true;
    }
    return false;
}