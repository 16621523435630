import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import Iframe from '../../helpers/iframe';
import { useSelector } from 'react-redux';


const Reports = () => {

	const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    document.title = 'Roxus | Reports';
  }, []);

  return (
    <div className='reports'>
      <Iframe source={currentUser?.company?.reportUrl} />
    </div>
  );
};

export default Reports;
