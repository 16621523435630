import React, { useState, useEffect } from 'react';
import axiosConfig from '../../utils/axios-config';
import cookies from 'js-cookie';
import { Countries }  from '../../constants/country';
import {
    Row,
    Col,
    Card,
    Form,
    Select,
    Input,
    Divider,
    Avatar,
    Upload,
    Spin,
    Tag,
    message,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import GraButton from '../button/gra-button';
import './company-tab.scss';
import { useParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;

const EditCompanyTab = () => {
    const params = useParams();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [form] = Form.useForm();
    const [currentCompany, setCurrentCompany] = useState({});

    const auth = useSelector((state) => state.auth);
    const { currentUser } = auth;

    useEffect(() => {
        document.title = 'Roxus | Edit Company';
		fetchCompany();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
    };

    const fetchCompany = async () => {
		console.log("Params", params);
		return await axiosConfig({
			method: 'get',
			url: `/api/settings/companies/${params.companyId}`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				let company = response.data.data;
				console.log('Company', company);
                setCurrentCompany(company);
                form.setFieldsValue(company);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	};

    const onFinish = async (values) => {
        console.log('Received values of form: ', values);

        try {
            setUpdateLoading(true);
            const response = await axiosConfig({
                url: `/api/settings/companies/${params.companyId}`,
                data: values,
                method: 'put',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            console.log(`response`, response);
            message.success('Update Company SUCCESSFULLY!');
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setUpdateLoading(false);
        }
    };

    return (
        <div className='edit-company-tab'>
            <Row>
                <Col span={24}>
                    <Card title='Edit Company'>
                        <Spin spinning={updateLoading}>
                            <Form
                                {...formItemLayout}
                                form={form}
                                name='register'
                                onFinish={onFinish}
                                scrollToFirstError
                            >
                                <Row>
                                    <Col span={10}>
                                        <Form.Item
                                            colon={false}
                                            name='name'
                                            label='Company Name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Company Name!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='companyNumber'
                                            label='Company Number'
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='website'
                                            label='Website'
                                            rules={[
                                                {
                                                    type: "url",
                                                    message: "Website must be a valid url."
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='numberOfEmployees'
                                            label='No. of Employees'
                                        >
                                            <Input />
                                            
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='fieldOfExpertise'
                                            label='Field of Expertise'
                                        >
                                            <Input
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col offset={2} span={10}>
                                        <Form.Item
                                            colon={false}
                                            name='billingStreet'
                                            label='Billing Street'
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='billingCity'
                                            label='Billing City'
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='billingState'
                                            label='Billing State'
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='billingCountry'
                                            label='Billing Country'
                                        >
                                            <Select placeholder='Select Country' showSearch>
                                                {
                                                    Countries.map(c => {
                                                        return <Option value={c.name}>{c.name}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='billingZip'
                                            label='Billing Zip'
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Divider />
                                    <Col span={24} className='submit-button'>
                                        <Form.Item>
                                            <GraButton
                                                className='button-gra-inv'
                                                type='submit'
                                            >
                                                Update Company
                                            </GraButton>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default EditCompanyTab;
