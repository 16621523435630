import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Form, Select, Checkbox, Input, message, Spin } from 'antd';
import { UserMessage } from '../../constants/user';
import axiosConfig from '../../utils/axios-config';
import GraButton from '../button/gra-button';
import './signup.scss';

const { Option } = Select;

const SignUpModal = ({ visible, onCancel, width, onSuccess }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const formItemLayout = {
		labelCol: {
			xs: {
				span: 24,
			},
			sm: {
				span: 8,
			},
		},
		wrapperCol: {
			xs: {
				span: 24,
			},
			sm: {
				span: 16,
			},
		},
	};

	const onFinish = (values) => {
		// console.log('Received values of form: ', values);
		setLoading(true);

		axiosConfig({
			method: 'post',
			url: '/api/nanonets/users',
			data: values,
		})
			.then((response) => {
				// console.log(response);
				setLoading(false);
				onSuccess(values.email);
				onCancel();
				message.success(UserMessage.CreateUser_200);
			})
			.catch((error) => {
				if (error.response) {
					message.error(error.response.data);
				} else if (error.request) {
					message.error(UserMessage.CreateUser_400);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
				setLoading(false);
			});
	};

	const tailFormItemLayout = {
		wrapperCol: {
			xs: {
				span: 24,
				offset: 0,
			},
			sm: {
				span: 16,
				offset: 8,
			},
		},
	};

	return (
		<Modal
			className='signup-modal'
			width={width}
			onCancel={onCancel}
			footer={null}
			visible={visible}
			title='CREATE YOUR ROXUS ACCOUNT'
		>
			<Spin spinning={loading}>
				<Form
					{...formItemLayout}
					form={form}
					name='register'
					onFinish={onFinish}
					scrollToFirstError
				>
					<Form.Item
						name='firstName'
						label='First Name'
						rules={[
							{
								required: true,
								message: 'Please input your First Name!',
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name='lastName'
						label='Last Name'
						rules={[
							{
								required: true,
								message: 'Please input your Last Name!',
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item name='company' label='Company'>
						<Input />
					</Form.Item>

					<Form.Item
						name='email'
						label='E-mail'
						rules={[
							{
								type: 'email',
								message: 'The input is not valid E-mail!',
							},
							{
								required: true,
								message: 'Please input your E-mail!',
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name='password'
						label='Password'
						rules={[
							{
								required: true,
								message: 'Please input your password!',
							},
						]}
						hasFeedback
					>
						<Input.Password />
					</Form.Item>

					<Form.Item
						name='confirm'
						label='Confirm Password'
						dependencies={['password']}
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please confirm your password!',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (
										!value ||
										getFieldValue('password') === value
									) {
										return Promise.resolve();
									}

									return Promise.reject(
										new Error(
											'The two passwords that you entered do not match!'
										)
									);
								},
							}),
						]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item
						name='phoneNumber'
						label='Phone Number'
						rules={[
							{
								required: true,
								message: 'Please input your phone number!',
							},
						]}
					>
						<Input
							style={{
								width: '100%',
							}}
						/>
					</Form.Item>

					<Form.Item name='gender' label='Gender'>
						<Select placeholder='Select Your Gender'>
							<Option value={1}>Male</Option>
							<Option value={2}>Female</Option>
							<Option value={0}>Other</Option>
						</Select>
					</Form.Item>

					<Form.Item
						name='agreement'
						valuePropName='checked'
						rules={[
							{
								validator: (_, value) =>
									value
										? Promise.resolve()
										: Promise.reject(
												new Error(
													'Should accept agreement'
												)
										  ),
							},
						]}
						{...tailFormItemLayout}
					>
						<Checkbox>
							I have read the{' '}
							<Link to='/agreement'>agreement</Link>
						</Checkbox>
					</Form.Item>
					<Form.Item {...tailFormItemLayout}>
						<GraButton type='submit' className='button-gra-inv'>
							Get Started
						</GraButton>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	);
};

export default SignUpModal;
