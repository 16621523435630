import React, { useEffect, useCallback, useRef } from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import cookies from 'js-cookie';
import { Spin } from 'antd';
import { checkAuthenticate } from '../../services/user-services';
import { useDispatch, useSelector } from 'react-redux';
import { refreshAccessToken } from '../../redux/users';

const PrivateRoute = (props) => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth);
    
    const refreshUser = async() => {
        try {
            const accessToken = cookies.get('access_token');
            const refreshToken = cookies.get('refresh_token');
            const data = { accessToken, refreshToken };
            await dispatch(refreshAccessToken(data));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (checkAuthenticate()) {
            refreshUser();
        }
        return () => {
            isMounted = false;
        };
    },[]);

    if (!checkAuthenticate()) {
        return <Redirect to='/login' />;
    } else {
        if (user.status === 'loading') {
            return (
                <Spin spinning={user.status === 'loading'}>
                    <Route {...props} />
                </Spin>
            );
        }
        else if (user.status === 'failed') {
            cookies.remove('access_token');
            cookies.remove('refresh_token');
            return <Redirect to='/login' />;
        }
    }
    return <Route {...props} />;
};

export default withRouter(PrivateRoute);
