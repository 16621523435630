import React, { useState, useEffect } from 'react';
import { Spin, Table, Image , Row, Col, Input, Card, Tag } from 'antd';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './occasion-table.scss';
import { SearchOutlined } from '@ant-design/icons';
import { delay } from '../../helpers/helpers';

const RecordingDetailsTable = ({ streamId }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState({});
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    let isMounted = true;
    if (currentUser && currentUser.id && streamId && isMounted) { 
      fetchTable()
    }
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    applySearchCriteria();
  }, [searchCriteria]);

  const applySearchCriteria = () => {
    let filterData = tableData;
    if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
      filterData = filterData.filter((row) => 
        row.jsonData.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1);
      setFilteredData(filterData);
    }
    setFilteredData(filterData);
  }

  const filterByKeyword = (e) => {
    delay(function(){
      setSearchCriteria({...searchCriteria, keyword: e.target.value});
    }, 500);
  }
 
  const fetchTable = async() => {
    await axiosConfig({
      method: 'get',
      url: `/api/cognitive/streams/${streamId}/occasions`,
      headers: {
        Authorization: `Bearer ${cookies.get('access_token')}`,
      },
    })
    .then((response) => {
      console.log('Occasion', response.data.data);
      const data = response.data.data.map((res, index) => {
        index++;
        return {
          key: res.id,
          jsonData: res.jsonData,
          screenshotUrl: res.screenshotUrl,
          color: res.color,
          startTime: res.startTime,
          endTime: res.endTime
        };
      });
      setTableData(data);
      setFilteredData(data);
      setLoading(false);
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      setLoading(false);
      console.log(error.config);
    });
  };

  const columns = [
    {
        title: 'Id',
        dataIndex: 'key',
        key: 'key',
        width: 100
    },
    {
        title: 'Content',
        dataIndex: 'jsonData',
        key: 'jsonData',
        render: (text, record) => {
            let finalResult = '';
            let decisionJSX = <></>;
            let inputJSX = <></>;
            let contentJSX = <></>;
            let content = JSON.parse(text);
            let occasionContent = content.occasionContent;
            if (occasionContent && Array.isArray(occasionContent) && occasionContent.length > 0)
            contentJSX = occasionContent.map((content) => {
                if (content.indexOf('&quot;') > -1) {
                    content = content.replaceAll('&quot;', '"');
                    return <><b>{content}</b><br/></>;
                }
                else {
                    return <>{content}<br/></>;
                }
            });
            let occasionInput = content.occasionInput;
            if (occasionInput && Array.isArray(occasionInput) && occasionInput.length > 0) {
                inputJSX = occasionInput.map((input, index) => {
                    let question = input.questionName;
                    let answer = input.answer;
                    let questionJSX = <></>;
                    let answerJSX = <></>;
                    if (!question && !answer) {
                        return;
                    }
                    if (question) {
                        questionJSX = <><span><b>Question #{index + 1}</b>: {question}</span><br/></>
                    };
                    if (answer) {
                        answerJSX = <span><b>Answer #{index + 1}</b>: {answer}</span>
                    }
                    if (index == 0) {
                        return <><br/>{questionJSX}{answerJSX}<br/></>;
                    }
                    else 
                    {
                        return <>{questionJSX}{answerJSX}<br/></>;
                    }
                });
            }
            
            let occasionDecision = content.occasionDecision;
            if (occasionDecision && Array.isArray(occasionDecision) && occasionDecision.length > 0) {
                decisionJSX = occasionDecision.map((decision, index) => {
                    if (index === 0) {
                        return <><br/><Tag color='#108ee9'>{decision}</Tag></>;
                    }
                    else {
                        return <Tag color='#108ee9'>{decision}</Tag>;
                    }
                });
            }
            finalResult = <Card className={record.color ? `bg-${record.color}` : ''}>{contentJSX}{inputJSX}{decisionJSX}</Card>;
            return finalResult;
        }
    },
    {
        title: 'Screenshot',
        dataIndex: 'screenshotUrl',
        key: 'screenshotUrl',
        render: (text) => {
            return (<Image
                width={200}
                src={text}
              />);
        }
    },
    {
        title: 'Start Time',
        dataIndex: 'startTime',
        key: 'startTime',
        width: 180,
    },
    {
        title: 'End Time',
        dataIndex: 'endTime',
        key: 'endTime',
        width: 180,
    },
  ];

  return (
    <Spin spinning={loading}>
      <Row className='search-area' gutter={{lg: 16}}>
        <Col span = {3} style={{paddingTop: '10px'}}>
          <span><b>Occasions:</b> {filteredData?.length}</span>
        </Col>
        <Col span={8} offset={13}>
          <Input id='search-box' onKeyUp={filterByKeyword} addonBefore={<SearchOutlined />} placeholder='Search (Content)'/>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        className='occasion-table'
      />
    </Spin>
  );
};

export default RecordingDetailsTable;
