import React, { useState } from 'react';
import { Card, Divider, Row, Col, Space, Spin, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import GraButton from '../button/gra-button';
import ReactCodeInput from 'react-code-input';
import Activate from '../../assets/images/activate/handshake.png';
import axiosConfig from '../../utils/axios-config';
import cookies from 'js-cookie';
import './activation-tab.scss';
import { updateUserActivation } from '../../redux/users';

const ActivationTab = () => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [sendEmailLoading, setSendEmailLoading] = useState(false);
    const [validateLoading, setValidateLoading] = useState(false);
    const [digitCode, setDigitCode] = useState('');
    const dispatch = useDispatch();

    const submitActivationCode = async () => {
        try {
            setValidateLoading(true);
            console.log(`digitCode`, digitCode);
            if (digitCode.length !== 6) {
                message.error('Activation Code must contain 6 digits');
                return;
            }
            var response = await axiosConfig({
                url: `/api/nanonets/register/activate/${digitCode}`,
                method: 'post',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            message.success(response.data.message);
            dispatch(updateUserActivation());
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setValidateLoading(false);
        }
    };

    const sendActivationEmail = async () => {
        try {
            setSendEmailLoading(true);
            var response = await axiosConfig({
                url: '/api/nanonets/register/sendactivationcode',
                method: 'post',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });

            message.success(response.data.message);
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setSendEmailLoading(false);
        }
    };

    return (
        <div className='activation-tab'>
            <Card title='Activate Account'>
                <Row justify='center' align='middle' className='content'>
                    <Col span={4}>
                        <img src={Activate} width={160} alt='' />
                    </Col>
                    <Col span={16}>
                        <p>
                            Hi{' '}
                            {`${currentUser ? currentUser.firstName : ''} ${
                                currentUser ? currentUser.lastName : ''
                            }`}
                            , please follow these steps to activate your
                            account:
                        </p>
                        <p>
                            1. Check you inbox and search for the{' '}
                            <b>last email</b> with subject:{' '}
                            <b>Welcome to Roxus OCR</b>
                        </p>
                        <p>
                            2. Enter the <b>6-digit code</b> from the email to
                            the field below
                        </p>
                        <p>
                            3. Click on the button <b>Activate</b>
                        </p>
                        <p>
                            <i>
                                Note: In case you cannot find the email or the
                                code is expired, please click on the button{' '}
                                <b>RESEND EMAIL</b> below.
                            </i>
                        </p>
                    </Col>
                    <Col className='mt-md'>
                        <div>
                            <b>6-DIGIT VALIDATION CODE</b>
                        </div>
                        <div className='mt-sm'>
                            <ReactCodeInput
                                onChange={(value) => {
                                    setDigitCode(value);
                                }}
                                fields={6}
                            />
                        </div>
                    </Col>
                    <Divider />
                    <Col span={24} className='submit-button'>
                        <Space size='middle'>
                            <Spin spinning={validateLoading}>
                                <GraButton
                                    onClick={submitActivationCode}
                                    className='button-gra-inv'
                                    type='submit'
                                >
                                    Activate
                                </GraButton>
                            </Spin>

                            <Spin spinning={sendEmailLoading}>
                                <GraButton
                                    onClick={sendActivationEmail}
                                    className='button-gra-inv'
                                    type='submit'
                                >
                                    Resend Email
                                </GraButton>
                            </Spin>
                        </Space>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default ActivationTab;
