import React, { useState, useEffect } from 'react';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import axiosConfig from '../../utils/axios-config';
import cookies from 'js-cookie';
import { Countries }  from '../../constants/country';
import {
    Row,
    Col,
    Card,
    Form,
    Spin,
	Table,
	Input,
	Button,
	Popconfirm,
	Modal,
    Select,
    Divider,
    message
} from 'antd';
import { delay } from '../../helpers/helpers';
import './departments-tab.scss';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;

const DepartmentsTab = () => {
    const params = useParams();
    const [filteredData, setFilteredData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(true);
    const [createLoading, setCreateLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
	const [searchCriteria, setSearchCriteria] = useState({});
	const currentUser = useSelector((state) => state.auth.currentUser);

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    
    const [currentDepartment, setCurrentDepartment] = useState({});

    const [createForm] = Form.useForm();
    const [updateForm] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
    };

    const showCreateModal = () => {
        setCreateModalOpen(true);
    };
    const handleCreateOk = () => {
        createForm.submit();
    };
    const handleCreateCancel = () => {
        setCreateModalOpen(false);
    };

    const showUpdateModal = async (record) => {
        console.log("Edit Record", record);
        setCurrentDepartment(record);
        updateForm.setFieldsValue(record);
        setUpdateModalOpen(true);
    };

    const handleUpdateOk = () => {
        updateForm.submit();
    };
    const handleUpdateCancel = () => {
        setUpdateModalOpen(false);
    };

    useEffect(() => {
        document.title = 'Roxus | Manage Departments';
		fetchTable();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const createDepartment = async (values) => {
        console.log('Received values of form: ', values);

        try {
            setCreateLoading(true);
            const response = await axiosConfig({
                url: `/api/settings/companies/${params.companyId}/departments`,
                data: values,
                method: 'post',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            console.log(`response`, response);
            message.success('Create Department SUCCESSFULLY!');
            await fetchTable();
            setCreateModalOpen(false);
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setCreateLoading(false);
        }
    };

    const updateDepartment = async (values) => {
        console.log('Received values of form: ', values);

        try {
            setUpdateLoading(true);
            const response = await axiosConfig({
                url: `/api/settings/departments/${currentDepartment.key}`,
                data: values,
                method: 'put',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            console.log(`response`, response);
            message.success('Update Department SUCCESSFULLY!');
            await fetchTable();
            setUpdateModalOpen(false);
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setUpdateLoading(false);
        }
    };

    const fetchTable = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/settings/companies/${params.companyId}/departments`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				const data = response.data.data.map((res, index) => {
					index++;
					return {
						key: res.id,
						no: res.id,
						name: res.name,
						createdTime: res.createdTime,
						modifiedTime: res.modifiedTime,
					};
				});
				console.log('Table Data', data);
				setTableData(data);
				setFilteredData(data);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

    const deleteDepartment = async (departmentId) => {
		await axiosConfig({
			method: 'delete',
			url: `/api/settings/departments/${departmentId}`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				fetchTable();
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	};

    const applySearchCriteria = () => {
		let filterData = tableData;
		if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
			filterData = filterData.filter(
				(row) =>
					row.Name
						.toLowerCase()
						.indexOf(searchCriteria.keyword.toLowerCase()) > -1
			);
			setFilteredData(filterData);
		}
		setFilteredData(filterData);
	};

	const filterByKeyword = (e) => {
		delay(function () {
			setSearchCriteria({ ...searchCriteria, keyword: e.target.value });
		}, 500);
	};

    const columns = [
		{
			title: 'Action',
			key: 'action',
			dataIndex: 'action',
			render: (text, record) => {
				return (
					<>
						<Button onClick={async () => await showUpdateModal(record)}>
							Edit
						</Button>
						<Popconfirm
							placement='top'
							title={'Are you sure to delete this department?'}
							onConfirm={() => deleteDepartment(record.key)}
							okText='Yes'
							cancelText='No'
						>
							<Button type='danger'>Delete</Button>
						</Popconfirm>
					</>
				);
			},
		},
		{
			title: 'Department Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Created Time',
			key: 'createdTime',
			dataIndex: 'createdTime',
            render: (text) => {
				return (
				  text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
				);
			  },
		},
		{
			title: 'Modified Time',
			key: 'modifiedTime',
			dataIndex: 'modifiedTime',
            render: (text) => {
				return (
				  text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
				);
			  },
		},
	];

    return (
        <>
            <Spin spinning={loading}>
                <Row className='search-area' gutter={{ lg: 8 }}>
                    <Col span={8}>
                        <Button type='primary' onClick={showCreateModal}>
                            Create
                        </Button>
                    </Col>
                    <Col span={8} offset={8}>
                        <Input
                            id='search-box'
                            onKeyUp={filterByKeyword}
                            addonBefore={<SearchOutlined />}
                            placeholder='Search'
                        />
                    </Col>
                </Row>
                <Table columns={columns} dataSource={filteredData} />
            </Spin>
            <Form
                {...formItemLayout}
                form={createForm}
                name='register'
                onFinish={createDepartment}
                scrollToFirstError
                loading={createLoading}
            >
                <Modal title="Create Department" visible={createModalOpen} onOk={handleCreateOk} onCancel={handleCreateCancel} okText="Create Department">
                    <Row>
                        <Col span={24}>
                            <Spin spinning={createLoading}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            colon={false}
                                            name='name'
                                            label='Department Name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Department Name!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Spin>
                        </Col>
                    </Row>
                </Modal>
            </Form>
            <Form
                {...formItemLayout}
                form={updateForm}
                name='register'
                onFinish={updateDepartment}
                scrollToFirstError
                loading={updateLoading}
            >
                <Modal title="Update Department" visible={updateModalOpen} onOk={handleUpdateOk} onCancel={handleUpdateCancel} okText="Update Department">
                    <Row>
                        <Col span={24}>
                            <Spin spinning={updateLoading}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            colon={false}
                                            name='name'
                                            label='Department Name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Department Name!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Spin>
                        </Col>
                    </Row>
                </Modal>
            </Form>
        </>
    );
};

export default DepartmentsTab;
