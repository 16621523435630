import React, { useState } from 'react';
import { Layout, Tooltip, Badge, Avatar, Popover } from 'antd';
import { BellFilled } from '@ant-design/icons';
import './header.scss';
import LogoWhiteImg from '../../assets/images/common/logo-white.png';
import AccPopContent from '../account-popover/account-popover';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';

const { Header } = Layout;

const HeaderComp = () => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const fullName = currentUser
        ? currentUser?.firstName + ' ' + currentUser?.lastName
        : '';
    const [visible, setVisible] = useState(false);

    const avatar = () => {
        return currentUser && currentUser.profileImage ? (
            <Avatar
                size='large'
                className='avatar'
                src={currentUser.profileImage}
            />
        ) : (
            <Avatar size='medium' className='avatar' icon={<UserOutlined />} />
        );
    };

    return (
        <Header className='header'>
            <Link to='/'>
                <div className='logo'>
                    <img width={100} src={LogoWhiteImg} alt='' />
                </div>
            </Link>
            {currentUser && (
                <div className='right-header'>
                    <div className='notification'>
                        <Tooltip placement='bottom' title='Notifications'>
                            <Badge size='small' count={5}>
                                <BellFilled style={{ fontSize: '18px' }} />
                            </Badge>
                        </Tooltip>
                    </div>
                    <div className='avatar'>
                        <Popover
                            className='avatar-popover'
                            trigger='click'
                            placement='bottomLeft'
                            visible={visible}
                            onClick={() => setVisible(!visible)}
                            content={
                                <AccPopContent
                                    onCancel={() => setVisible(false)}
                                    fullName={fullName}
                                    jobTitle={currentUser?.jobTitle}
                                />
                            }
                        >
                            {avatar()}
                        </Popover>
                    </div>
                </div>
            )}
        </Header>
    );
};

export default HeaderComp;
