import React, {useEffect, useState } from 'react';
import axiosConfig from '../../utils/axios-config';
import cookies from 'js-cookie';
import OccasionTable from '../../components/occasion-table/occasion-table';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import "./stream-details.scss";

const RecordingDetails = () => {
    const params = useParams();
    const [stream, setStream] = useState();
    const currentUser = useSelector((state) => state.auth.currentUser);

    useEffect(() => {
        document.title = "Roxus | Assistant Stream Details";
        let isMounted = true;
        if (currentUser && currentUser.id && isMounted) {
            fetchStream()
        }
        return () => { isMounted = false };
      }, []);

    const fetchStream = async() => {
        return await axiosConfig({
            method: 'get',
            url: `/api/cognitive/streams/${params.streamId}`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
        .then((response) => {
            let stream = response.data.data;
            console.log('Stream', stream);
            setStream(stream);
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    };

    return (
        <div className='stream-details-page'>
            <Row justify='space-between'>
                <Col className='title'><span className='text'>ASSISTANT STREAM DETAILS</span></Col>
            </Row>
            <Row className='details'>
                <span className='label'>Id:&nbsp;</span><span>{stream?.id}</span>&nbsp;&nbsp;&nbsp;<span className='pipe'>|</span>&nbsp;&nbsp;&nbsp;
                <span className='label'>Department Name:&nbsp;</span><span>{stream?.departmentName}</span>&nbsp;&nbsp;&nbsp;<span className='pipe'>|</span>&nbsp;&nbsp;&nbsp;
                <span className='label'>Process Name:&nbsp;</span><span>{stream?.processName}</span>&nbsp;&nbsp;&nbsp;<span className='pipe'>|</span>&nbsp;&nbsp;&nbsp;
                <span className='label'>Machine Name:&nbsp;</span><span>{stream?.machineName}</span>&nbsp;&nbsp;&nbsp;<span className='pipe'>|</span>&nbsp;&nbsp;&nbsp;
                <span className='label'>OS Username:&nbsp;</span><span>{stream?.osUserName}</span>&nbsp;&nbsp;&nbsp;<span className='pipe'>|</span>&nbsp;&nbsp;&nbsp;
                <span className='label'>Start Time:&nbsp;</span><span>{stream?.startTime}</span>&nbsp;&nbsp;&nbsp;<span className='pipe'>|</span>&nbsp;&nbsp;&nbsp;
                <span className='label'>End Time:&nbsp;</span><span>{stream?.endTime}</span>
            </Row>
            <OccasionTable streamId={params.streamId} />
        </div>
    )
}
export default RecordingDetails;
