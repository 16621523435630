import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Tag, Row, Col, Input } from 'antd';
import { delay } from '../../helpers/helpers';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './stream-table.scss';
import { Link } from 'react-router-dom';

const StreamTable = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState({});
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    let isMounted = true;
    if (currentUser && currentUser.id && isMounted) {
      fetchTable();
    } 
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    applySearchCriteria();
  }, [searchCriteria]);

  const applySearchCriteria = () => {
    let filterData = tableData;
    if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
      filterData = filterData.filter((row) => 
        row.departmentName.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1
        || row.machineName.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1
        || row.processName.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1
        || row.osUserName.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1
        );
      setFilteredData(filterData);
    }
    setFilteredData(filterData);
  }

  const filterByKeyword = (e) => {
    delay(function(){
      setSearchCriteria({...searchCriteria, keyword: e.target.value});
    }, 500);
  }

  const fetchTable = async() => {
    await axiosConfig({
      method: 'get',
      url: `/api/cognitive/streams`,
      headers: {
        Authorization: `Bearer ${cookies.get('access_token')}`,
      },
    }).then((response) => {
        console.log(response);
        const data = response.data.data.map((res, index) => {
          index++;
          return {
            key: res.id,
            no: res.id,
            departmentName: res.departmentName,
            machineName: res.machineName,
            osUserName: res.osUserName,
            processName: res.processName,
            startTime: res.startTime,
            endTime: res.endTime
          };
        });
        console.log('Table Data', data);
        setTableData(data);
        setFilteredData(data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        setLoading(false);
        console.log(error.config);
      });
  };

  const columns = [
    {
        title: 'Action',
        key: 'action',
        dataIndex: 'action',
        render: (text, record) => {
            return (<Link to={"streams/" + record.key} target="_blank" rel="noopener noreferrer"><FileSearchOutlined /> View Details</Link>);
        }
    },
    {
        title: 'Id',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'Department Name',
        dataIndex: 'departmentName',
        key: 'departmentName',
    },
    {
        title: 'Process Name',
        dataIndex: 'processName',
        key: 'processName',
    },
    {
        title: 'Machine Name',
        dataIndex: 'machineName',
        key: 'machineName',
    },
    {
        title: 'OS Username',
        dataIndex: 'osUserName',
        key: 'osUserName'
    },
    {
        title: 'Start Time',
        key: 'startTime',
        dataIndex: 'startTime',
    },
    {
        title: 'End Time',
        key: 'endTime',
        dataIndex: 'endTime',
      },
  ];

  return (
    <Spin spinning={loading}>
      <Row className='search-area' gutter={{lg: 8}}>
        <Col span={12} offset={12}>
          <Input id='search-box' onKeyUp={filterByKeyword} addonBefore={<SearchOutlined />} placeholder='Search (Department Name, Process Name, Machine Name, OS Username)'/>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
      />
    </Spin>
  );
};

export default StreamTable;
