import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Layout, Row, Col, Form, Input, Spin, Space, message } from 'antd';
import HeaderComp from '../../components/header/header';
import HandShake from '../../assets/images/activate/handshake.png';
import SadFace from '../../assets/images/activate/sad-face.png';
import Activate from '../../assets/images/activate/Roxus_Icons_Individual_Tick.png';
import GraButton from '../../components/button/gra-button';
import axiosConfig from '../../utils/axios-config';
import { delay } from '../../helpers/helpers';
import './activate-page.scss';

const SetupPasswordPage = ({ history }) => {

    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [validateCode, setValidateCode] = useState({});
    const [pageContent, setPageContent] = useState("");

    const onFinish = async (values) => {
        setLoading(true);

        try {
            console.log(values);
            let password = values.password;
            let confirmPassword = values["confirm-password"];
    
            if (password != confirmPassword) {
                message.error("Password and confirmation password do not match.");
                return;
            }
    
            let setupRequest = {};
            setupRequest.password = password;
            setupRequest.userId = validateCode?.userId;
    
            await axiosConfig({
                method: 'post',
                data: setupRequest,
                url: `/api/nanonets/setup-password`,
            })
                .then((response) => {
                    
                    console.log("Setup Password response", response);
                    message.success("Setup Password SUCCESSFULLY, will redirect to login page")
                    setLoading(false);
                    delay(3000);
                    history.push("/login")
                })
                .catch((error) => {
                    message.error("Setup Password FAILED")
                    setLoading(false);
                });
        }
        catch(ex)
        {

        }
        finally{
            setLoading(false);
        }

    };

    const validateActivationCode = async () => {
        setLoading(true);
        await axiosConfig({
            method: 'post',
            url: `/api/nanonets/validate/${params.activationCode}`,
        })
            .then((response) => {
                console.log("Validate Response", JSON.stringify(response));
                setValidateCode(response.data.data);
                delay(1000);
                setLoading(false);
            })
            .catch((error) => {
                setValidateCode(null);
                setLoading(false);
            });
    };

    useEffect(() => {
        console.log("Activation Code", params.activationCode);
        validateActivationCode();
    }, [params]);

    return (
        <Layout>
            <HeaderComp />
            <Spin spinning={loading}>
                <Layout className='activate-page'>
                    {validateCode ? (<Row justify='center' align='middle' className='content'>
                        <Col>
                            <img src={Activate} width={200} alt='' />{' '}
                        </Col>
                        <Col offset={1}>
                            <div>
                                <span className='activate-status'>
                                    Hi {validateCode?.firstName}
                                </span>
                            </div>
                            <div className='mt-md'>
                                <span>
                                    Please set up your password for the first time.
                                </span>
                            </div>
                            <Form
                                name='password-form'
                                className='login-form mt-md'
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name='password'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input size='large' type='password' placeholder='Input your password' />
                                </Form.Item>
                                <Form.Item
                                    name='confirm-password'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                    ]}
                                >
                                    <Input size='large' type='password' placeholder='Confirm your password' />
                                </Form.Item>

                                <div className='mt-md'>
                                    <GraButton className='button-gra-inv' type='submit'>
                                        CONFIRM
                                    </GraButton>
                                </div>
                            </Form>
                        </Col>
                    </Row>) : (<Row justify='center' align='middle' className='content'>
                        <Col>
                            <img src={SadFace} width={200} alt='' />{' '}
                        </Col>
                        <Col offset={1}>
                            <div>
                                <span className='activate-status'>
                                    Oops! The activation code
                                    <br /> is INVALID or EXPIRED
                                </span>
                            </div>
                            <div className='mt-md'>
                                <span>
                                    Please contact Roxus administrator (<a href='mailto:help@roxus.io'>help@roxus.io</a>) for more information.
                                </span>
                            </div>
                        </Col>
                    </Row>)}

                </Layout>
            </Spin>
        </Layout>
    );
};

export default withRouter(SetupPasswordPage);
