import React from 'react';

import "./background.scss"

const LogInBackground = () => {
    return (
        <div className="login-background">
        </div>
    )
}

export default LogInBackground;