import React, { useState, useEffect } from 'react';
import { Spin, Table, Tag, Row, Col, Input, Select, Checkbox, Typography } from 'antd';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './recording-details-table.scss';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import { delay } from '../../helpers/helpers';
const { Option } = Select;

const RecordingDetailsTable = ({agentChannel, recordingId, changePlayTime}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [channel, setChannel] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState({});
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    let isMounted = true;
    if (currentUser && currentUser.id && recordingId && isMounted) { 
      fetchTable()
    }
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setChannel(agentChannel);
    }
    return () => { isMounted = false };
  }, [agentChannel]);

  useEffect(() => {
    applySearchCriteria();
  }, [searchCriteria]);

  const applySearchCriteria = () => {
    let filterData = tableData;
    if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
      filterData = filterData.filter((row) => 
        row.sentenceText.toLowerCase().indexOf(searchCriteria.keyword.toLowerCase()) > -1);
      setFilteredData(filterData);
    }
    if (searchCriteria.sentiments && searchCriteria.sentiments.length > 0) {
      filterData = filterData.filter((row) => 
        searchCriteria.sentiments.includes(row.sentimentStatus));
    }
    if (searchCriteria.tag == true) {
      filterData = filterData.filter((row) => row.tags);
    }
    setFilteredData(filterData);
  }

  const filterByKeyword = (e) => {
    delay(function(){
      setSearchCriteria({...searchCriteria, keyword: e.target.value});
    }, 500);
  }

  const filterBySentiment = (value) => {
    setSearchCriteria({...searchCriteria, sentiments: value});
  }

  const filterByTag = (e) => {
    setSearchCriteria({...searchCriteria, tag: e.target.checked});
  }
 
  const fetchTable = async() => {
    await axiosConfig({
      method: 'get',
      url: `/api/cognitive/recordings/${recordingId}/transcriptions`,
      headers: {
        Authorization: `Bearer ${cookies.get('access_token')}`,
      },
    }).then((response) => {
      const data = response.data.data.map((res, index) => {
        index++;
        return {
          key: res.id,
          no: res.sentenceNumber,
          channel: res.channel,
          confidence: res.confidence,
          sentenceText: res.sentenceDisplay,
          offsetTime: res.offsetTime,
          offsetTimecode: res.offsetTimecode,
          sentimentStatus: res.sentimentStatus,
          sentimentPositive: res.sentimentPositive,
          sentimentNeutral: res.sentimentNeutral,
          sentimentNegative: res.sentimentNegative,
          tags: res.tags,
          tagEvidences: res.tagEvidences,
          created: res.created,
          createdBy: res.createdBy
        };
      });
      setTableData(data);
      setFilteredData(data);
      setLoading(false);
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      setLoading(false);
      console.log(error.config);
    });
  };

  const columns = [
    {
      title: 'Time Code',
      dataIndex: 'offsetTimecode',
      key: 'offsetTimecode',
      width: 120,
      render: (timeCode, record) => {
        return (<span className='timecode' onClick={() => changePlayTime(record.offsetTime)}>{timeCode}</span>)
      }
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
      width: 100,
      render: (text) => {
        if (channel == null) {
          return text;
        }
        else {
          if (channel == text) return (<Tag color={'cyan'}>Agent</Tag>);
          else return (<Tag color={'magenta'}>Client</Tag>);
        }
      }
    },
    {
      title: 'Sentiment Score',
      key: 'sentimentScore',
      dataIndex: 'sentimentScore',
      width: 160,
      render: (text, record) => {
        if (record.sentimentStatus) {
          return (
            <Row className='sentiment-score'>
              <Col className={record.sentimentStatus === 'positive'?'positive-left':''} span={16}>
                Positive
              </Col>
              <Col className={record.sentimentStatus === 'positive'?'positive-right':''} span={8}>
                {record.sentimentPositive}
              </Col>
              <Col className={record.sentimentStatus === 'neutral'?'neutral-left':''} span={16}>
                Neutral
              </Col>
              <Col className={record.sentimentStatus === 'neutral'?'neutral-right':''} span={8}>
                {record.sentimentNeutral}
              </Col>
              <Col className={record.sentimentStatus === 'negative'?'negative-left':''} span={16}>
                Negative
              </Col>
              <Col className={record.sentimentStatus === 'negative'?'negative-right':''} span={8}>
                {record.sentimentNegative}
              </Col>
            </Row>
          )
        }
        else {return ""}
      }
    },
    {
      title: 'Confidence',
      key: 'confidence',
      dataIndex: 'confidence',
      width: 120,
      render: (confidence) => {
        let color = '';
        if (confidence >= 90) {
          color = 'green';
        }
        else if (confidence >= 80) {
          color = 'blue';
        }
        else {
          color = 'red';
        }
        return (
          <Tag color={color}>
            {confidence}
          </Tag>
        );
      },
    },
    {
      title: 'Sentence Text',
      dataIndex: 'sentenceText',
      key: 'sentenceText',
      width: 500,
      render: (text, record) => {
        if (record.tagEvidences) {
          let evidenceArray = record.tagEvidences.split('|');
          return (<Highlighter
            highlightClassName="mark"
            searchWords={evidenceArray}
            autoEscape={true}
            textToHighlight={text}
          />);
        }
        else {
          return text;
        }
      }
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 150,
      render: (text) => {
        if (text) {
          let allTags = text.split('|');
          return allTags.map(tag => {
            let tagSplit = tag.split(';')
            let tagName = tagSplit[0];
            let tagColor = tagSplit[1];
            return (<Tag color={tagColor}>{tagName}</Tag>)
          })
        }
        else {
          return '';
        }
      }
    }
  ];

  return (
    <Spin spinning={loading}>
      <Row className='search-area' gutter={{lg: 16}}>
        <Col span = {3} style={{paddingTop: '10px'}}>
          <span><b>Sentences:</b> {filteredData?.length}</span>
        </Col>
        <Col span={8} offset={5}>
          <Input id='search-box' onKeyUp={filterByKeyword} addonBefore={<SearchOutlined />} placeholder='Search (Sentence Text)'/>
        </Col>
        <Col span={5}>
          <Select id='select-sentiment' onChange={filterBySentiment} mode="multiple" style={{ width: '100%' }} placeholder="Select Sentiment">
            <Option value="negative">Negative</Option>
            <Option value="neutral">Neutral</Option>
            <Option value="positive">Positive</Option>
          </Select>
        </Col>
        <Col span={3}>
          <Checkbox onChange={filterByTag}>Has Tag(s)?</Checkbox>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        scroll={{y: 500}}
        pagination={{ pageSize: 100 }}
        className='transcription-table'
      />
    </Spin>
  );
};

export default RecordingDetailsTable;
