import React, { useState, useEffect } from 'react';
import { Typography, Spin, Table, Tag, Space, Tabs, Row, Col, Form, Input, Select, Button, Modal, message } from 'antd';
import {
  CopyOutlined
} from '@ant-design/icons';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import parse from 'html-react-parser';
import { capitalize } from '../../utils/string-util';
import { useSelector } from 'react-redux';
import './otp-table.scss';
const { Text, Link } = Typography;
const { Option } = Select;

const OtpTable = () => {
  const [tableData, setTableData] = useState([]);
  const [username, setUserName] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [otpModal, setOtpModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const [createForm] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    createForm.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const createOtp = async (values) => {
    console.log('Received values of form: ', values);
    try {
      setCreateLoading(true);
      const response = await axiosConfig({
        url: `/api/roxuslogging/create-otp-config`,
        data: values,
        method: 'post',
        headers: {
          Authorization: `Bearer ${cookies.get('access_token')}`,
        },
      });
      console.log(`response`, response);
      message.success('Create OTP Config SUCCESSFULLY!');
    } catch (error) {
      console.log(`error`, error);
      if (error.response) {
        console.log(`error`, error);
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        message.error(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        message.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        message.error(error.message);
      }
    } finally {
      setCreateLoading(false);
      setIsModalOpen(false);
      fetchTable();
    }
  }

  const info = () => {
    Modal.info({
      title: 'OTP Information',
      content: (
        <div>
          <p>Username:<br /> <Text style={{ fontWeight: "bold", fontSize: "1.2rem" }}>{username}&nbsp;<Text onClick={() => {
            navigator.clipboard.writeText(username);
            message.info("Username Copied");
          }} style={{ cursor: 'pointer', fontWeight: "bold", fontSize: "1rem" }}><CopyOutlined /></Text></Text>
            {password && <><br />Password:<br /> <Text style={{ fontWeight: "bold", fontSize: "1.2rem" }}>{password}&nbsp;<Text onClick={() => {
              navigator.clipboard.writeText(password);
              message.info("Password Copied");
            }} style={{ cursor: 'pointer', fontWeight: "bold", fontSize: "1rem" }}><CopyOutlined /></Text></Text></>}
            <br />OTP:<br /> <Text style={{ fontWeight: "bold", fontSize: "1.2rem" }}>{otp}&nbsp;
              <Text onClick={() => {
                navigator.clipboard.writeText(otp);
                message.info("OTP Copied");
              }} style={{ cursor: 'pointer', fontWeight: "bold", fontSize: "1rem" }}><CopyOutlined /></Text></Text>
          </p>
        </div >
      ),
      onOk() { setUserName(''); setPassword(''); setOtp(''); setOtpModal(false) },
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (currentUser && currentUser.id) {
      fetchTable().then((response) => {
        const data = response.data.map((res, index) => {
          index++;
          return {
            key: res.id,
            no: index,
            customerName: res.customerName,
            platform: res.platform,
            environment: res.environment,
            username: res.username,
            authType: res.authType,
          };
        });
        console.log('Table Data', data);
        if (isMounted) {
          setTableData(data);
          setLoading(false);
        }
      })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          if (isMounted) {
            setLoading(false);
          }
          console.log(error.config);
        });
    }
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    // do some validation perhaps
    if (otpModal) {
      info();
    }
  }, [otpModal]); // add person in dependency list 

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
  };

  const fetchTable = async () => {
    return await axiosConfig({
      method: 'get',
      url: `/api/roxuslogging/2fa-appconfigs`,
      headers: {
        Authorization: `Bearer ${cookies.get('access_token')}`,
      },
    })
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Space size='middle'>
            <Button
              type='primary'
              onClick={async () => await getOtp(record.key)}
            >
              Get OTP
            </Button>
          </Space>
        );
      },
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform'
    },
    {
      title: 'Environment',
      key: 'environment',
      dataIndex: 'environment'
    },
    {
      title: 'Username',
      key: 'username',
      dataIndex: 'username'
    },
    {
      title: 'Auth Type',
      key: 'authType',
      dataIndex: 'authType'
    }
  ];

  const getOtp = async (configId) => {
    setLoading(true);
    await axiosConfig({
      method: 'post',
      url: `/api/roxuslogging/get-otp/${configId}`,
      headers: { Authorization: `Bearer ${cookies.get('access_token')}` },
    })
      .then((response) => {
        console.log(response);
        let otpResponse = response.data.data;
        setOtp(otpResponse.otp);
        setUserName(otpResponse.username);
        setPassword(otpResponse.password);
        setOtpModal(true);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          message.error(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        message.error(error.response.data);
        setLoading(false);
      });
  }

  return (
    <Spin spinning={loading}>
      <Row className='search-area' gutter={{ lg: 8 }}>
        <Col span={8}>
          <Button onClick={showModal} type='primary'>
            Create
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={tableData}
      />
      <Form
        {...formItemLayout}
        form={createForm}
        name='register'
        onFinish={createOtp}
        scrollToFirstError
        loading={createLoading}
      >
        <Modal title="Create OTP" visible={isModalOpen} okText='Create OTP' onOk={handleOk} onCancel={handleCancel}>
          <Row>
            <Col span={24}>
              <Spin spinning={createLoading}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      colon={false}
                      name='customerName'
                      label='Customer Name'
                      rules={[
                        {
                          required: true,
                          message:
                            'Please input Customer Name!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      colon={false}
                      name='platform'
                      label='Platform'
                      rules={[
                        {
                          required: true,
                          message:
                            'Please input Platform!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      colon={false}
                      name='clientSecret'
                      label='Client Secret'
                      rules={[
                        {
                          required: true,
                          message:
                            'Please input Client Secret!',
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      colon={false}
                      name='username'
                      label='Username'
                      rules={[
                        {
                          required: true,
                          message:
                            'Please input Username!',
                        },
                      ]}
                    >
                      <Input />

                    </Form.Item>
                    <Form.Item
                      colon={false}
                      name='password'
                      label='Password'
                      rules={[
                        {
                          required: true,
                          message:
                            'Please input Password!',
                        },
                      ]}
                    >
                      <Input.Password
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Spin>
            </Col>
          </Row>
        </Modal>
      </Form>
    </Spin>
  );
};

export default OtpTable;
