import React from 'react';
import { withRouter } from 'react-router-dom'
import { Row, Divider } from 'antd';
import GraButton from '../button/gra-button';
import cookies from 'js-cookie';
import './account-popover.scss';

const AccPopContent = ({ history, onCancel, fullName, jobTitle = null }) => {
    const goToAccountPage = () => {
        history.push("/account");
        onCancel();
    }

    const goToLoginPage = () => {
        cookies.remove("access_token");
        cookies.remove("refresh_token");
        history.push("/login");
    }

    return (
        <div className="account-popover">
            <Row>
                <span className="full-name">{fullName}</span>
            </Row>
            {
                jobTitle &&
                <Row>
                    <span className="title">{jobTitle}</span>
                </Row>
            }
            <Divider />
            <Row className="edit-profile">
                <GraButton className="button-gra-inv" onClick={goToAccountPage}>Edit Profile</GraButton>
            </Row>
            <Row>
                <GraButton className="button-gra-inv" onClick={goToLoginPage}>Log Out</GraButton>
            </Row>
        </div>

    )
}

export default withRouter(AccPopContent);
