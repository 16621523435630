import React, { useState, useEffect } from 'react';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Spin,
	Table,
	Tag,
	Row,
	Col,
	Input,
	Button,
	Popconfirm,
	Avatar,
	List,
	Modal,
	Form,
	InputNumber,
	Select,
	message
} from 'antd';
import { Countries } from '../../constants/country';
import { delay } from '../../helpers/helpers';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './company-table.scss';
import { withRouter, useHistory, Link } from 'react-router-dom';

const { Option } = Select;

const CompanyTable = () => {
	let history = useHistory();
	const [filteredData, setFilteredData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchCriteria, setSearchCriteria] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [createLoading, setCreateLoading] = useState(false);
	const currentUser = useSelector((state) => state.auth.currentUser);

	const [createForm] = Form.useForm();

	const formItemLayout = {
		labelCol: {
			xs: {
				span: 24,
			},
			sm: {
				span: 8,
			},
		},
	};

	useEffect(() => {
		let isMounted = true;
		if (currentUser && currentUser.id && isMounted) {
			fetchTable();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		applySearchCriteria();
	}, [searchCriteria]);

	const createCompany = async (values) => {
		console.log('Received values of form: ', values);

        try {
            setCreateLoading(true);
            const response = await axiosConfig({
                url: `/api/settings/companies`,
                data: values,
                method: 'post',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            console.log(`response`, response);
            message.success('Create Company SUCCESSFULLY!');
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setCreateLoading(false);
			setIsModalOpen(false);
			fetchTable();
        }
	}

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		createForm.submit();
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const applySearchCriteria = () => {
		let filterData = tableData;
		if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
			filterData = filterData.filter(
				(row) =>
					row.Name
						.toLowerCase()
						.indexOf(searchCriteria.keyword.toLowerCase()) > -1
			);
			setFilteredData(filterData);
		}
		setFilteredData(filterData);
	};

	const filterByKeyword = (e) => {
		delay(function () {
			setSearchCriteria({ ...searchCriteria, keyword: e.target.value });
		}, 500);
	};

	const fetchTable = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/settings/companies`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				const data = response.data.data.map((res, index) => {
					index++;
					return {
						key: res.id,
						no: res.id,
						name: res.name,
						companyNumber: res.companyNumber,
						website: res.website,
						description: res.description,
						numberOfEmployees: res.numberOfEmployees,
						fieldOfExpertise: res.fieldOfExpertise,
						country: res.country,
						created: res.createdTime,
						modified: res.modifiedTime,
					};
				});
				console.log('Table Data', data);
				setTableData(data);
				setFilteredData(data);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

	const deleteCompany = async (companyId) => {
		await axiosConfig({
			method: 'delete',
			url: `/api/settings/companies/${companyId}`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				fetchTable();
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	};

	const changePage = (page, companyId) => {
		switch (page) {
			case 'create':
				history.push('/settings/create-company');
				break;
			case 'view':
				history.push(`/settings/companies/${companyId}`);
				break;
			default:
				break;
		}
	};

	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	const columns = [
		{
			title: 'Action',
			key: 'action',
			dataIndex: 'action',
			render: (text, record) => {
				return (
					<>
						<Button onClick={() => changePage('view', record.key)}>
							View Details
						</Button>
						{/* <Popconfirm
							placement='top'
							title={'Are you sure to delete this setting?'}
							onConfirm={() => deleteSetting(record.key)}
							okText='Yes'
							cancelText='No'
						>
							<Button type='danger'>Delete</Button>
						</Popconfirm> */}
					</>
				);
			},
		},
		{
			title: 'Company Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Company Number',
			dataIndex: 'companyNumber',
			key: 'companyNumber',
		},
		{
			title: 'Website',
			dataIndex: 'website',
			key: 'website',
			render: (text) => {
				if (text) {
					return <a href={text} target='_blank'>{text}</a>;
				}
				return "";
			}
		},
		{
			title: 'Number of Employees',
			dataIndex: 'numberOfEmployees',
			key: 'numberOfEmployees',
		},
		{
			title: 'Field of Expertise',
			dataIndex: 'fieldOfExpertise',
			key: 'fieldOfExpertise',
		},
		{
			title: 'Country',
			dataIndex: 'country',
			key: 'country',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: 'Created Time',
			key: 'created',
			dataIndex: 'created',
			render: (text) => {
				return (
					text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
				);
			},
		},
		{
			title: 'Modified Time',
			key: 'modified',
			dataIndex: 'modified',
			render: (text) => {
				return (
					text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
				);
			},
		},
	];

	return (
		<Spin spinning={loading}>
			<Row className='search-area' gutter={{ lg: 8 }}>
				<Col span={8}>
					<Button onClick={showModal} type='primary'>
						Create
					</Button>
				</Col>
				<Col span={8} offset={8}>
					<Input
						id='search-box'
						onKeyUp={filterByKeyword}
						addonBefore={<SearchOutlined />}
						placeholder='Search'
					/>
				</Col>
			</Row>
			<Table columns={columns} dataSource={filteredData} />
			<Form
				{...formItemLayout}
				form={createForm}
				name='register'
				onFinish={createCompany}
				scrollToFirstError
				loading={createLoading}
			>
				<Modal title="Create Company" visible={isModalOpen} okText='Create Company' onOk={handleOk} onCancel={handleCancel}>
					<Row>
						<Col span={24}>
							<Spin spinning={createLoading}>
								<Row>
									<Col span={24}>
										<Form.Item
											colon={false}
											name='name'
											label='Company Name'
											rules={[
												{
													required: true,
													message:
														'Please input Company Name!',
												},
											]}
										>
											<Input />
										</Form.Item>
										<Form.Item
											colon={false}
											name='companyNumber'
											label='Company Number'
										>
											<Input />
										</Form.Item>
										<Form.Item
                                            colon={false}
                                            name='website'
                                            label='Website'
                                            rules={[
                                                {
                                                    type: "url",
                                                    message: "Website must be a valid url."
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
										<Form.Item
											colon={false}
											name='numberOfEmployees'
											label='No. of Employees'
										>
											<Input />

										</Form.Item>
										<Form.Item
											colon={false}
											name='fieldOfExpertise'
											label='Field of Expertise'
										>
											<Input
												style={{
													width: '100%',
												}}
											/>
										</Form.Item>
										<Form.Item
											colon={false}
											name='billingStreet'
											label='Billing Street'
										>
											<Input />
										</Form.Item>
										<Form.Item
											colon={false}
											name='billingCity'
											label='Billing City'
										>
											<Input />
										</Form.Item>
										<Form.Item
											colon={false}
											name='billingState'
											label='Billing State'
										>
											<Input />
										</Form.Item>
										<Form.Item
											colon={false}
											name='billingCountry'
											label='Billing Country'
										>
											<Select placeholder='Select Country' showSearch>
												{
													Countries.map(c => {
														return <Option value={c.name}>{c.name}</Option>
													})
												}
											</Select>
										</Form.Item>
										<Form.Item
											colon={false}
											name='billingZip'
											label='Billing Zip'
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
							</Spin>
						</Col>
					</Row>
				</Modal>
			</Form>
		</Spin>
	);
};

export default CompanyTable;
