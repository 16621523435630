import React from 'react';
import { Row, Col, Avatar } from 'antd';
import './account-profile.scss';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const AccountProfile = () => {
    const currentUser = useSelector((state) => state.auth.currentUser);

    const fullName = currentUser
        ? currentUser.firstName + ' ' + currentUser?.lastName
        : '';

    const avatar = () => {
        return currentUser?.profileImage ? (
            <Avatar
                size={64}
                className='avatar'
                src={currentUser?.profileImage}
            />
        ) : (
            <Avatar size='large' className='avatar' icon={<UserOutlined />} />
        );
    };

    return (
        <div className='account-profile'>
            <Row className='profile-content' justify='start' align='middle'>
                <Col>{avatar()}</Col>
                <Col offset={2}>
                    <div>
                        <span className='full-name'>{fullName}</span>
                    </div>
                    {currentUser?.jobTitle && (
                        <div>
                            <span>{currentUser?.jobTitle}</span>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default AccountProfile;
