import React from 'react';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CompanyTab from '../../components/company-tab/company-tab';
import DepartmentsTab from '../../components/departments-tab/departments-tab';
import LicensesTab from "../../components/licenses-tab/licenses-tab";
import UsersTab from "../../components/users-tab/users-tab";
import ModelsTab from "../../components/models-tab/models-tab";
import './company-details.scss';
const { TabPane } = Tabs;

const Account = ({ history }) => {

    const currentUser = useSelector((state) => state.auth.currentUser);

    const getCurrentTab = () => {
        const url = window.location.href;
        let urls = url.split('#');
        switch (urls[1]) {
            case 'details':
                return 'details';
            case 'departments':
                return 'departments';
            case 'licenses':
                return 'licenses';
            case 'users':
                return 'users';
            case 'models':
                return 'models';
            default:
                return 'details';
        }
    };

    const changeTab = (key) => {
        history.push(`#${key}`);
    };

    return (
        <div className='company-details-page'>
            <div className='company-details-content'>
                <span className='company-details-text'>COMPANY DETAILS</span>
            </div>
            <Tabs defaultActiveKey={getCurrentTab()} onChange={changeTab}>
                <TabPane tab='COMPANY INFO' key='details'>
                    <CompanyTab />
                </TabPane>
                <TabPane tab='DEPARTMENTS' key='departments'>
                    <DepartmentsTab />
                </TabPane>
                <TabPane tab='USERS' key='users'>
                    <UsersTab />
                </TabPane>
                <TabPane tab='LICENSES' key='licenses'>
                    <LicensesTab />
                </TabPane>
                <TabPane tab='MODELS' key='models'>
                    <ModelsTab />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default withRouter(Account);
